import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M5 9.889 2.635 8.856a.8.8 0 0 1-.014-1.46l6.125-2.819a3 3 0 0 1 2.508 0l6.125 2.819a.8.8 0 0 1-.014 1.46L15 9.889m-10 0 4.326 1.623a2 2 0 0 0 1.355.018l2.319-.8m-8-.841v3.947c0 1.645 4.057 2.636 6.727 1.937"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "m10 7.624 4.218 1.94a1 1 0 0 1 .582.909v3.777m0 0 1.2 1.656m-1.2-1.656-1.2 1.656"
    }, null, -1)
  ])))
}
export default { render: render }