import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M2 2h.937a1 1 0 0 1 .948.68l.641 1.9m2.44 7.227h8.582a1 1 0 0 0 .975-.777l1.197-5.226a1 1 0 0 0-.975-1.223H4.526m2.44 7.225-2.44-7.225m2.44 7.225c-2.76 0-2.76 3.097.551 3.097h8.276"
    }, null, -1),
    _createElementVNode("ellipse", {
      cx: "7.517",
      cy: "16.452",
      stroke: "currentColor",
      rx: "1.655",
      ry: "1.548"
    }, null, -1),
    _createElementVNode("ellipse", {
      cx: "15.793",
      cy: "16.452",
      stroke: "currentColor",
      rx: "1.655",
      ry: "1.548"
    }, null, -1)
  ])))
}
export default { render: render }