import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": ".8",
      d: "M18 18h-1M2 18h2m11.5 0V8.194m0 9.806H17m-1.5 0h-2m2-9.806 1.444 1.49a.2.2 0 0 1 .056.139V18m-1.5-9.806-2 1.032m0 0V18m0-8.774V6.732a.2.2 0 0 0-.064-.146l-1.047-.973M13.5 18h-1.111M8.5 18V7.677M8.5 18H7m1.5 0H10M8.5 7.677V2.46a.2.2 0 0 1 .336-.147l3.553 3.3M8.5 7.678 7 6.645m0 0L4.07 9.166a.2.2 0 0 0-.07.152V18M7 6.645V18m-3 0h3m5.389-12.387V10.5m0 7.5v-7.5m0 7.5H10m2.389-7.5-2.295 1.441a.2.2 0 0 0-.094.17V18"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M12.389 17.8v-6.938a.2.2 0 0 0-.306-.17l-1.99 1.25a.2.2 0 0 0-.093.169V17.8c0 .11.09.2.2.2h1.989a.2.2 0 0 0 .2-.2"
    }, null, -1)
  ])))
}
export default { render: render }