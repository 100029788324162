import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": ".8",
      d: "M18 18h-1M2 18h1.111M17 18h-3.5m3.5 0V8.2a.2.2 0 0 0-.2-.2h-3.1a.2.2 0 0 0-.2.2V18m0 0H12m-3.889 0V2.2c0-.11.09-.2.2-.2H11.8c.11 0 .2.09.2.2V18m-3.889 0h-1.5m1.5 0H12m-8.889 0V6.845c0-.11.09-.2.2-.2h3.1c.11 0 .2.09.2.2V18m-3.5 0h3.5"
    }, null, -1)
  ])))
}
export default { render: render }