import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#869DF0",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g stroke=\"currentColor\" stroke-linecap=\"round\" clip-path=\"url(#a)\"><path stroke-linejoin=\"round\" stroke-width=\".4\" d=\"M10.167 13.278V14.6a.4.4 0 0 1-.4.4H1.4a.4.4 0 0 1-.4-.4V3.35c0-.222.18-.4.4-.4h6.22\"></path><path stroke-width=\".4\" d=\"M2.697 4.958h4.074M2.697 6.464h4.074M2.697 7.971h4.074\"></path><path d=\"M2.697 12.028c1.189-.988 2.46-1.647 3.169-1.647 1.188 0-.396 2.305.396 1.976s1.188-1.317 1.98-1.317c.793 0-.856 1.317.397 1.317.792 0 1.188-.659 1.188-.659\"></path><path stroke-linejoin=\"round\" d=\"m13.784 4.577-2.067-.608-2.067-.607m4.134 1.215a.68.68 0 0 0 .86-.495l.119-.49a1.057 1.057 0 0 0-.722-1.254l-3.593-1.056a.946.946 0 0 0-1.197.69l-.12.49a.76.76 0 0 0 .519.9v0m4.134 1.215c-.509.766.063 1.69.093 2.435.009.21-.111.39-.267.52l-3.476 2.915m0 0-1.68-4.431c-.076-.198-.098-.42.005-.597.365-.628 1.282-1.115 1.19-2.057m.485 7.085.864-3.533m0 0c.455.134.784-.19.88-.583.264-1.078-1.114-1.485-1.378-.405-.096.393.042.854.498.988\"></path></g><defs><clipPath id=\"a\"><rect fill=\"#fff\"></rect></clipPath></defs>", 2)
  ])))
}
export default { render: render }