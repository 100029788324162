import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "m8.81 13.885-3.683-3.757c-.366-.374-.637-.906-.41-1.378.13-.273.356-.462.728-.35.496.148 1.539.727 2.403 1.24a1.02 1.02 0 0 0 1.445-.43l.26-.544m-.743 5.22h5.143m-5.143 0H6.684a1 1 0 0 0-.978 1.21l.457 2.115A1 1 0 0 0 7.14 18h7.72a1 1 0 0 0 .977-.79l.457-2.114a1 1 0 0 0-.978-1.21h-1.364m0 0 .937-1.59a2 2 0 0 0-.297-2.416L13.4 8.666m-3.848 0c.486.541 1.168.879 1.924.879 1.473 0 2.667-1.28 2.667-2.859s-1.194-2.858-2.667-2.858S8.81 5.108 8.81 6.686c0 .769.282 1.466.742 1.98"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M16.65 12.971h1.684V2h-16v10.971H5.28"
    }, null, -1)
  ])))
}
export default { render: render }