import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "12",
  height: "12",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      width: "12",
      height: "12",
      fill: "#869DF0",
      rx: "6"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "#fff",
      "stroke-linecap": "round",
      d: "m4 4 2 2m2 2L6 6m0 0 2-2M6 6 4 8"
    }, null, -1)
  ])))
}
export default { render: render }