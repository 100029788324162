import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "m8.876 12.492 2.563 3.347a.4.4 0 0 0 .69-.097L16.6 4.4m-7.724 8.092-1.971 2.24a.4.4 0 0 1-.7-.265V9.964m2.671 2.528L16.6 4.4m0 0L6.205 9.964M16.6 4.4 3.756 8.323a.4.4 0 0 0-.018.76l2.467.88"
    }, null, -1)
  ])))
}
export default { render: render }