<template>
  <router-link
    v-if="href && !external_link"
    v-bind:to="href"
    class="text-link">
    <div
      v-if="icon"
      class="text-link__icon"
      v-bind:class="{ 'bg-white text-core-700': dark }"
      v-bind:style="`width: ${width}; height: ${height}`">
      <font-awesome-icon
        v-bind:icon="icon"
        v-bind:size="size" />
    </div>

    <span
      v-if="label"
      class="text-link__name"
      v-bind:class="{ 'text-link__name--responsive': responsive }"
      v-bind:style="text_margin"
      >{{ label }}</span
    >
    <span
      v-if="description"
      class="mt-1 w-full truncate font-normal text-core-500">
      {{ description }}
    </span>
    <slot></slot>
  </router-link>

  <a
    v-else-if="href && external_link"
    v-bind:target="`${external_same_tab ? '' : '_blank'}`"
    v-bind:href="href"
    class="text-link">
    <div
      v-if="icon"
      class="text-link__icon"
      v-bind:class="{ 'bg-white text-core-700': dark }"
      v-bind:style="`width: ${width}; height: ${height}`">
      <font-awesome-icon
        v-bind:icon="icon"
        v-bind:size="size" />
    </div>

    <span
      v-if="label"
      class="text-link__name"
      v-bind:class="{ 'text-link__name--responsive': responsive }"
      v-bind:style="text_margin"
      >{{ label }}</span
    >
  </a>

  <div
    v-else
    class="text-link">
    <div
      v-if="icon"
      class="text-link__icon"
      v-bind:class="{ 'bg-white text-core-700': dark }"
      v-bind:style="`width: ${width}; height: ${height}`">
      <font-awesome-icon
        v-bind:icon="icon"
        v-bind:size="size" />
    </div>

    <span
      v-if="label"
      class="text-link__name"
      v-bind:class="{ 'text-link__name--responsive': responsive }"
      v-bind:style="text_margin">
      <span class="truncate">{{ label }}</span>
      <span
        v-if="description"
        class="text-link__description mt-1 w-full truncate font-normal text-core-500">
        {{ description }}
      </span>
      <slot></slot>
    </span>
  </div>
</template>

<script>
  export default {
    props: [
      'href',
      'icon',
      'size',
      'label',
      'label_margin',
      'width',
      'height',
      'dark',
      'description',
      'external_link',
      'external_same_tab',
      'responsive'
    ],
    computed: {
      text_margin() {
        return `margin-left: ${this.label_margin / 4}rem`;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @use '@/assets/css/utils/mixins' as mixin;

  .text-link {
    @apply flex h-full w-full flex-wrap;
    @include mixin.text-link();

    .text-link__icon {
      @include mixin.round-icon();
    }

    .text-link__name {
      @apply flex flex-wrap truncate;

      &.text-link__name--responsive {
        @apply hidden lg:block;
      }
    }
  }
</style>
