import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "21",
  height: "20",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g clip-path=\"url(#a)\"><path fill=\"#F8B44E\" d=\"M9.439 5.59a1.143 1.143 0 0 1 1.98 0l4.082 7.07c.44.763-.11 1.715-.99 1.715H6.346c-.88 0-1.43-.952-.99-1.714z\"></path><path fill=\"#fff\" d=\"M10.67 7.057c.26 0 .466.213.458.472l-.101 3.122a.457.457 0 0 1-.457.443h-.283a.457.457 0 0 1-.457-.443l-.1-3.122a.457.457 0 0 1 .456-.472zm-.243 5.9a.72.72 0 0 1-.523-.216.71.71 0 0 1-.216-.522q0-.302.216-.515a.72.72 0 0 1 .523-.216q.293 0 .514.216.225.213.225.515 0 .204-.105.372a.8.8 0 0 1-.27.267.7.7 0 0 1-.364.1\"></path></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M3.429 3h14v14h-14z\"></path></clipPath></defs>", 2)
  ])))
}
export default { render: render }