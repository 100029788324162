<template>
  <modal-box
    v-if="show_modal"
    width_class="w-full max-w-2xl"
    overflow_body
    v-bind:header="true"
    v-bind:footer="false"
    v-on:close="onclose">
    <template v-slot:modal-header>
      <h1 class="text-3xl font-bold text-core-600">
        {{ `${participant.nom} ${participant.prenom}` }}
        <div
          class="mt-2 w-full border-l-4 pl-3 text-lg font-normal leading-none">
          PIF
        </div>
      </h1>
    </template>

    <template v-slot:modal-body>
      <div
        class="ressources__download__link__container justify-center"
        v-if="pif_signee">
        <h3 class="ressources__download__link__label py-10">- PIF</h3>
        <div
          class="ressources__download__link__button"
          v-on:click="
            () =>
              download_pif(
                participant.pif.id,
                'validee',
                participant.pif.valideeFileName
              )
          ">
          <div
            class="ressources__standalone_button__content"
            title="Télécharger le PIF signé">
            <font-awesome-icon
              class="text-xl"
              icon="download" />
          </div>
        </div>
      </div>

      <div
        class="ml-4 flex w-full flex-wrap"
        v-else-if="!pif_signee && (pif_disponible || pif_uploaded)">
        <div
          class="ressources__download__link__container flex w-full"
          v-if="pif_disponible">
          <h3 class="ressources__download__link__label">- PIF à signer</h3>
          <div
            class="ressources__download__link__button"
            v-on:click="
              () =>
                download_pif(participant.pif.id, '', participant.pif.fileName)
            ">
            <div
              class="ressources__standalone_button__content"
              title="Télécharger le PIF à signer">
              <font-awesome-icon
                class="text-xl"
                icon="download" />
            </div>
          </div>
        </div>
        <div
          class="ressources__download__link__container flex w-full"
          v-if="pif_disponible || pif_uploaded">
          <h3 class="ressources__download__link__label">
            - PIF signé
            <span class="text-sm font-normal italic">(par le client)</span>
          </h3>
          <div
            class="ressources__download__link__button"
            v-on:click="
              () =>
                download_pif(
                  participant.pif.id,
                  'signee',
                  participant.pif.signeeFileName
                )
            "
            v-if="!participant.pif.signee && participant.pif.fichierSignee">
            <div
              class="ressources__standalone_button__content"
              title="Télécharger le PIF téléversé">
              <font-awesome-icon
                class="text-xl"
                icon="download" />
            </div>
          </div>
          <file-upload
            v-if="!participant.pif.signee"
            title="Téléverser le PIF signé"
            v-bind:file_uploaded="participant.pif.fichierSignee"
            v-bind:upload_url="`${pif_base_url}/${participant.pif.id}/file`"
            v-bind:refresh_session="refresh_session"
            id="pif-individuelle-upload" />
        </div>
      </div>
    </template>

    <template v-slot:modal-footer> </template>
  </modal-box>
</template>
<script>
  import FileUpload from '@/components/utils/file-upload';
  import modalBox from '@/components/utils/modal-box.vue';

  export default {
    components: {
      modalBox,
      FileUpload
    },
    props: {
      show_modal: Boolean,
      participant: Object,
      onclose: Function,
      refresh_session: Function,
      pif_base_url: String,
      download_pif: Function
    },
    data: () => ({ loading: false }),
    computed: {
      pif_disponible() {
        const { pif } = this.participant;

        return pif && pif.id;
      },
      pif_uploaded() {
        const { pif } = this.participant;

        return pif && pif.id && pif.signeeFileName;
      },
      pif_signee() {
        const { pif } = this.participant;

        return pif && pif.id && pif.valideeFileName && pif.signee;
      }
    },
    async created() {},
    methods: {
      close() {
        this.detail = null;
        this.onclose();
      }
    }
  };
</script>
<style lang="scss" scoped>
  .ressources__download__link__container {
    @apply mb-3 flex cursor-pointer items-center;

    .ressources__download__link__button {
      @apply ml-3 flex items-center;

      .ressources__download__link__button__content {
        @apply flex h-8 w-full items-center justify-center rounded bg-accent-200 p-4 text-center text-sm font-bold uppercase text-core-500 shadow-md disabled:cursor-not-allowed disabled:opacity-75;
      }
    }

    .ressources__download__link__label {
      @apply ml-8 text-xl font-bold leading-none text-core-700;
    }

    &:hover {
      .ressources__download__link__button {
        .ressources__download__link__button__content {
          @apply bg-accent-300 text-core-600;
        }
      }

      .ressources__download__link__label {
        @apply text-primary-800;
      }
    }
  }
</style>
