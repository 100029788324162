<template>
  <transition
    name="modal"
    appear>
    <!-- Modal Outside Box -->
    <div v-bind:class="modal_mask_class_name">
      <!-- <div class="modal-wrapper"> -->
      <!-- Modal Inside Box -->
      <div
        class="modal-container"
        v-bind:class="[
          width_class,
          { 'modal-container--min-full-height': fill_height },
          overflow_class
        ]"
        v-on-click-outside="!no_outside_click ? closeModal : () => {}">
        <div
          v-if="header"
          class="modal-header">
          <div
            v-if="is_survey"
            class="survey-header">
            <div
              class="survey-anchor"
              v-bind:class="`border-${survey_color}`"></div>
            <slot name="modal-header"></slot>
            <div class="survey-label-wrapper">
              <div
                class="survey-label"
                v-bind:class="`bg-${survey_color} ${no_close_btn ? 'survey-label--no-close' : ''}`">
                {{ survey_label }}
              </div>
              <button
                v-if="is_survey && !no_close_btn"
                class="modal-btn-close"
                v-on:click.prevent.stop="closeSurveyModal">
                <font-awesome-icon
                  class="modal-btn-close__icon"
                  v-bind:icon="['far', 'times-circle']"
                  size="2x" />
              </button>
            </div>
          </div>

          <template v-else>
            <slot name="modal-header"></slot>
            <button
              v-if="!is_survey && !no_close_btn"
              class="modal-btn-close"
              v-on:click.prevent.stop="closeModal">
              <font-awesome-icon
                class="modal-btn-close__icon"
                v-bind:icon="['far', 'times-circle']"
                size="2x" />
            </button>
          </template>
        </div>

        <hr
          v-if="header && !is_survey"
          class="modal-hr" />

        <div
          v-if="body"
          class="modal-body"
          v-bind:class="[
            { 'modal_body--overflow-y': overflow_body },
            { 'modal_body--is-survey': is_survey },
            modal_body_class
          ]">
          <slot name="modal-body"></slot>
        </div>

        <hr
          v-if="footer"
          class="modal-hr" />

        <div
          v-if="footer"
          class="modal-footer">
          <slot name="modal-footer"> </slot>
          <div
            v-if="copyright"
            class="flex w-full text-xs text-core-500">
            CNCC Formation {{ new Date().getFullYear() }}
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </transition>
</template>

<script setup>
  import { vOnClickOutside } from '@vueuse/components';
</script>
<script>
  export default {
    props: {
      modal_mask_class_name: {
        type: String,
        default: 'modal-mask'
      },
      survey_color: {
        type: String,
        default: 'yellow-500'
      },
      header: {
        type: Boolean,
        default: true
      },
      body: {
        type: Boolean,
        default: true
      },
      footer: {
        type: Boolean,
        default: true
      },
      copyright: {
        type: Boolean,
        default: false
      },
      width_class: {
        type: String,
        default: 'w-11/12'
      },
      fill_height: {
        type: Boolean,
        default: false
      },
      overflow_body: {
        type: Boolean,
        default: false
      },
      is_survey: {
        type: Boolean,
        default: false
      },
      survey_label: {
        type: String,
        default: 'Questionnaire'
      },
      no_outside_click: {
        type: Boolean,
        default: false
      },
      no_close_btn: {
        type: Boolean,
        default: false
      },
      overflow_class: {
        type: String,
        default: 'overflow-hidden'
      },
      modal_body_class: {
        type: String,
        default: ''
      }
    },
    emits: ['close', 'close-survey', 'open'],
    data() {
      return {};
    },
    created() {
      this.$emit('open');
      this.$options.$emit('modal-open');
      window.addEventListener('keyup', this.handleEscapeKey);
    },
    beforeUnmount() {
      this.$options.$emit('modal-close');
      window.removeEventListener('keyup', this.handleEscapeKey);
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      closeSurveyModal() {
        this.$emit('close-survey');
      },
      handleEscapeKey(e) {
        if (e.key === 'Escape') {
          this.closeModal();
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @use '@/assets/css/utils/mixins' as mixin;

  .modal-mask {
    @apply fixed inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-75 lg:transition-opacity lg:delay-200 lg:ease-in-out;
    -webkit-transform: translateZ(999px);
    -moz-transform: translateZ(999px);
    -o-transform: translateZ(999px);
    transform: translateZ(999px);
    z-index: 999;
  }

  .modal-mask-partial {
    @apply fixed right-0 flex h-full items-center justify-center bg-white bg-opacity-25 lg:transition-opacity lg:delay-200 lg:ease-in-out;
    -webkit-transform: translateZ(999px);
    -moz-transform: translateZ(999px);
    -o-transform: translateZ(999px);
    transform: translateZ(999px);
    z-index: 49;
  } // .modal-wrapper {}
  .modal-default-button {
  }

  .modal-container {
    @apply mx-auto flex max-h-full flex-col bg-white shadow-md lg:max-h-[90vh] lg:rounded-lg;

    @include mixin.inflate-transition();
    &.modal-container--min-full-height {
      height: 100%;
    }
  }

  .modal-body,
  .modal-footer,
  .modal-header {
    @apply relative py-4 pl-6 pr-3;

    .survey-header {
      @apply my-6 flex w-full items-start justify-between;

      .survey-anchor {
        @apply absolute left-0 top-0 z-10 h-10 w-12 border-l-8 border-t-8;
      }

      .survey-label-wrapper {
        @apply flex items-center;

        .survey-label {
          @apply mr-6 inline-flex items-center whitespace-nowrap rounded-sm px-2 py-0.5 text-lg text-white shadow;
          &.survey-label--no-close {
            @apply mr-0;
          }
        }
      }
    }
  }

  .modal-footer,
  .modal-header {
    @apply flex w-full items-start justify-between lg:flex-wrap lg:items-center;
  }

  .modal-hr {
    @apply border-core-300;
  }

  .modal-body {
    @apply flex-1 overscroll-none px-4 py-4 lg:px-6;

    &.modal_body--overflow-y {
      @apply overflow-y-auto overscroll-none;
    }
    &.modal_body--is-survey {
      @apply relative w-full;
    }
  }

  .modal-header .modal-btn-close {
    .modal-btn-close__icon {
      @apply p-1 text-core-500;

      &:hover {
        @apply text-core-600;
      }
    }
  }

  /* Modal specific Vuejs animation on 'leave' w/ @include mixin.inflate-transition() */
  .modal-leave-to {
    opacity: 0;
  }
  .modal-leave-active .modal-container {
    transform: scale(0.7);
  }
</style>
