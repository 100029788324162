import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M2.667 13.334h10.666m-3.533-9 .91-.911a1 1 0 0 1 1.46.048l.538.615a1 1 0 0 1-.056 1.376l-.874.85M9.8 4.333 5.537 8.596a1 1 0 0 0-.268.486l-.415 1.827a.2.2 0 0 0 .238.24l1.923-.421a1 1 0 0 0 .483-.26l4.28-4.157M9.8 4.334l1.978 1.977"
    }, null, -1)
  ])))
}
export default { render: render }