import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M9.306 3.857v11.429m0-11.429v-1.25a1 1 0 0 0-1.441-.898L4.058 3.582a1 1 0 0 0-.558.898v9.75a1 1 0 0 0 .502.868l3.807 2.185a1 1 0 0 0 1.497-.867v-1.13m0-11.429h2.871a1 1 0 0 1 1 1v1.381m0 6.667v1.38a1 1 0 0 1-1 1h-2.87"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": ".4",
      d: "M7.371 8.62v1.428M14.23 8.143h-2.104a.4.4 0 0 0-.4.4V10.6c0 .22.18.4.4.4h2.103c.221 0 .4.179.4.4v.632a.4.4 0 0 0 .661.303l2.881-2.48a.4.4 0 0 0 .02-.588L15.31 6.43a.4.4 0 0 0-.68.286v1.026a.4.4 0 0 1-.4.4"
    }, null, -1)
  ])))
}
export default { render: render }