import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "8",
  height: "8",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#EA444C",
      d: "M0 4a4 4 0 1 1 8 0 4 4 0 0 1-8 0"
    }, null, -1)
  ])))
}
export default { render: render }