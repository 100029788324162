import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      width: "15",
      height: "15",
      x: "2.5",
      y: "2.5",
      stroke: "currentColor",
      rx: "7.5"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M9.125 12.072v-.095q.01-.924.184-1.471.18-.548.507-.885a4 4 0 0 1 .79-.617q.3-.19.537-.422a1.8 1.8 0 0 0 .378-.537 1.6 1.6 0 0 0 .14-.671q0-.442-.21-.766a1.4 1.4 0 0 0-.556-.497 1.6 1.6 0 0 0-.766-.18q-.383 0-.73.16a1.4 1.4 0 0 0-.577.497q-.229.333-.264.86H7.047q.035-.894.452-1.511a2.65 2.65 0 0 1 1.104-.94q.691-.318 1.526-.318.915 0 1.601.343.686.338 1.064.95.383.606.383 1.416 0 .557-.174 1.005a2.5 2.5 0 0 1-.497.79 3.4 3.4 0 0 1-.766.617q-.422.263-.686.547a1.74 1.74 0 0 0-.378.67q-.12.39-.13.96v.095zm.75 3.023a.97.97 0 0 1-.7-.289.96.96 0 0 1-.294-.706.94.94 0 0 1 .294-.696.96.96 0 0 1 .7-.293q.404 0 .697.293a.93.93 0 0 1 .298.696q0 .274-.14.502-.133.225-.357.358a.95.95 0 0 1-.497.134"
    }, null, -1)
  ])))
}
export default { render: render }