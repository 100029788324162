import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#777070",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M4.5 3v14a1 1 0 0 0 1 1h.205V2H5.5a1 1 0 0 0-1 1"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M15.384 4V3a1 1 0 0 0-1-1H5.705m9.679 14v1a1 1 0 0 1-1 1H5.705m0 0H5.5a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h.205m0 16V2M16.5 6l-.904 1.067-2.477 2.923a.4.4 0 0 0 .018.537l2.459 2.54.904.933"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "m13.838 6-.903 1.067-2.477 2.923a.4.4 0 0 0 .017.537l2.46 2.54.903.933"
    }, null, -1)
  ])))
}
export default { render: render }