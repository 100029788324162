import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "m5.714 11.382-.82.85a2 2 0 0 0-.421 2.123l.991 2.512a1 1 0 0 0 .93.633h6.465a1 1 0 0 0 .86-.49l1.495-2.525a2 2 0 0 0-.298-2.424L12.6 9.715m1.114-2.952c0 2.355-1.79 4.263-4 4.263s-4-1.908-4-4.263 1.791-4.263 4-4.263 4 1.909 4 4.263"
    }, null, -1)
  ])))
}
export default { render: render }