import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createTextVNode(";"),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M13.974 7.504c-.018-.04-.44-.978-1.386-1.923-.876-.876-2.382-1.92-4.588-1.92s-3.712 1.044-4.589 1.92c-.944.945-1.367 1.881-1.385 1.923a.3.3 0 0 0 0 .248c.018.039.44.977 1.385 1.922.877.875 2.383 1.92 4.589 1.92s3.712-1.045 4.589-1.92c.944-.945 1.367-1.882 1.385-1.922a.3.3 0 0 0 0-.248M8 10.984c-1.596 0-2.989-.582-4.142-1.726a6.9 6.9 0 0 1-1.213-1.63c.321-.602.73-1.151 1.213-1.631C5.011 4.852 6.404 4.271 8 4.271s2.989.58 4.142 1.726c.483.48.892 1.03 1.213 1.63-.324.621-1.948 3.356-5.355 3.356m0-5.696a2.339 2.339 0 1 0 0 4.678 2.339 2.339 0 0 0 0-4.678m0 4.068a1.729 1.729 0 1 1 0-3.458 1.729 1.729 0 0 1 0 3.458"
    }, null, -1)
  ])))
}
export default { render: render }