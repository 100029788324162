import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M11.334 6.896h.846A1.82 1.82 0 0 1 14 8.716v3.042a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8.896a2 2 0 0 1 2-2h1.334M8.243 10.075V1.757m0 0L5.818 4.134m2.425-2.377 2.424 2.377"
    }, null, -1)
  ])))
}
export default { render: render }