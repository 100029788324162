<template>
  <div
    class="inscription-card group"
    v-bind:class="[
      { 'inscription-card--head-list cursor-pointer': parcours },
      {
        [`cursor-pointer hover:shadow-lg`]:
          inscription.accesDetails && !parcours
      },
      {
        'inscription-card-disabled':
          (isFormateur && isFormateurStatusCancel) ||
          (isParticipant && isSessionStatusCancel) ||
          (!inscription.accesDetails && !parcours)
      }
    ]"
    v-on:click="cardAction">
    <div class="card__container">
      <div
        class="card__container--anchor"
        v-bind:class="type_anchor_color(inscription.type[0].id)"></div>

      <div class="container__content">
        <div class="content__type">
          <div
            v-for="{ id, label } in inscription.type"
            v-bind:key="id"
            class="type__label"
            v-bind:class="type_bg_color(id)">
            <span>{{ label }}</span>
          </div>
        </div>

        <div class="content__description">
          <div class="description__left">
            <div class="content__title">
              <font-awesome-icon
                v-if="parcours"
                v-bind:icon="
                  collapsed ? 'chevron-circle-right' : 'chevron-circle-down'
                "
                class="mr-2.5"
                v-bind:class="[
                  {
                    [`group-hover:${type_text_color(inscription.type[0].id)}`]:
                      inscription.accesDetails || parcours
                  }
                ]"
                size="1x" />
              <span
                class="content__title__text"
                v-bind:class="[
                  {
                    [`group-hover:${type_text_color(inscription.type[0].id)}`]:
                      (inscription.accesDetails && !isSessionStatusCancel) ||
                      parcours ||
                      !isParticipant
                  }
                ]">
                <span data_id="titre">
                  {{
                    `${inscription.titre}${inscription.familleStage ? ' (Stage)' : ''}${inscription.fuseauHoraire && inscription.fuseauHoraire !== 'UTC+1 Paris' ? ` - [Fuseau horaire ${inscription.fuseauHoraire}]` : ''}`
                  }}
                </span>
              </span>
              <template
                v-if="
                  inscription.accesDetails &&
                  !parcours &&
                  isParticipant &&
                  !isSessionStatusCancel
                ">
                <span class="content__title__legend">
                  <font-awesome-icon
                    class="title__legend__icon group-hover:text-accent-500"
                    icon="search" />
                  <span class="title__legend__text group-hover:text-accent-500">
                    Cliquer pour afficher les détails de la session
                  </span>
                </span>
              </template>
              <template
                v-else-if="
                  (!inscription.accesDetails && !parcours) ||
                  (inscription.accesDetails &&
                    isParticipant &&
                    isSessionStatusCancel)
                ">
                <span class="content__title__legend">
                  <font-awesome-icon
                    class="title__legend__icon group-hover:text-accent-500"
                    icon="search" />
                  <span class="title__legend__text group-hover:text-jinius-red">
                    Détails de la session indisponibles
                  </span>
                </span>
              </template>
            </div>

            <div
              v-if="inscription.reference"
              class="content__reference">
              Réf&nbsp;:
              <span
                data_id="reference"
                class="font-bold">
                {{ inscription.reference }}
              </span>
            </div>
            <div
              v-if="inscription.commandeReference"
              class="content__reference">
              Réf&nbsp;commande&nbsp;:
              <span
                data_id="reference"
                class="font-bold">
                {{ inscription.commandeReference }}
              </span>
            </div>

            <div class="flex flex-col space-y-1">
              <div
                v-if="
                  (inscription.sessionDates &&
                    !inscription.plateformeDureeAcces) ||
                  (inscription.moduleElearning &&
                    inscription.moduleElearning.accessible)
                ">
                <span
                  class="inline-flex w-4 items-center justify-center align-middle">
                  <font-awesome-icon
                    v-bind:icon="['far', 'calendar-alt']"
                    size="1x" />
                </span>

                <span
                  v-if="!inscription.dateDebut && !inscription.dateFin"
                  class="mx-2">
                  <em class="text-accent-500"
                    >Date{{
                      inscription.sessionDates.length > 1 ? 's' : null
                    }}&nbsp;:
                  </em>
                  {{ displayDates(inscription.sessionDates).join(', ') }}
                </span>

                <span
                  v-if="inscription.dateDebut && inscription.dateFin"
                  class="mx-2">
                  {{
                    `Accessible du ${inscription.dateDebut} ${inscription.dateFin ? `au ${inscription.dateFin}` : ''}`
                  }}
                </span>

                <template v-if="!produit">
                  <span
                    v-if="
                      inscription.plateformeDureeAcces &&
                      inscription.moduleElearning &&
                      inscription.moduleElearning.accessible
                    ">
                    {{
                      `Accessible pendant ${inscription.plateformeDureeAcces} jour${inscription.plateformeDureeAcces > 1 ? 's' : ''}`
                    }}

                    {{
                      inscription.moduleElearning.dateDebutAcces
                        ? `(du ${inscription.moduleElearning.dateDebutAcces} ${inscription.moduleElearning.dateFinAcces ? `au ${inscription.moduleElearning.dateFinAcces}` : ''})`
                        : ''
                    }}
                  </span>
                </template>
                <template v-else>
                  <span
                    v-if="
                      inscription.moduleElearning &&
                      inscription.moduleElearning.dateDebutAcces
                    ">
                    {{
                      `Accessible du ${inscription.moduleElearning.dateDebutAcces}${inscription.moduleElearning.dateFinAcces ? ` au ${inscription.moduleElearning.dateFinAcces}` : ''} `
                    }}
                  </span>
                </template>
              </div>

              <div
                v-if="(inscription.lieu || inscription.lieuIntra) && !parcours"
                class="flex items-center">
                <span
                  class="inline-flex w-4 items-center justify-center align-middle">
                  <font-awesome-icon
                    icon="map-marker-alt"
                    size="1x" />
                </span>
                <span class="ml-2.5 mr-1.5">
                  <em class="text-accent-500">Lieu&nbsp;: </em>
                  <span data_id="lieu">
                    {{ inscription.lieu || inscription.lieuIntra }}
                  </span>
                </span>
                <span
                  v-if="inscription.mode && inscription.mode.id === 2"
                  class="bg-jinius-dark-green px-1.5 py-0.5 align-text-top text-xs leading-none text-white shadow-sm">
                  {{ inscription.mode.label }}
                </span>
              </div>

              <div v-if="inscription.participants && !parcours">
                <span
                  class="inline-flex w-4 items-center justify-center align-middle">
                  <font-awesome-icon
                    icon="users"
                    size="1x" />
                </span>
                <span
                  class="mx-2"
                  v-bind:class="{
                    'cursor-pointer hover:underline':
                      inscription.participants.length >
                      participants_number_to_display
                  }"
                  v-on:click.stop="openParticipantsModal">
                  <em class="text-accent-500">Participants&nbsp;: </em>
                  {{ displayParticipants(inscription.participants).join(', ')
                  }}{{
                    inscription.participants.length >
                    participants_number_to_display
                      ? ` et ${inscription.participants.length - participants_number_to_display} autres personnes`
                      : ''
                  }}
                  <font-awesome-icon
                    v-if="
                      inscription.participants.length >
                      participants_number_to_display
                    "
                    icon="search-plus"
                    size="sm"
                    class="ml-1 text-accent-500" />
                </span>
              </div>

              <div v-if="inscription.organisateur && !parcours">
                <span
                  class="inline-flex w-4 items-center justify-center align-middle">
                  <font-awesome-icon
                    icon="tag"
                    size="1x" />
                </span>

                <span class="mx-2 align-middle">
                  <em class="text-accent-500">Organisateur&nbsp;: </em>
                  <span data_id="organisateur">
                    {{ inscription.organisateur }}
                  </span>
                </span>
              </div>
              <div>
                <div
                  v-if="inscription.sessionUrlWebinaire"
                  v-on:click.stop="
                    () => goToUrl(inscription.sessionUrlWebinaire)
                  "
                  class="my-2 flex w-72 cursor-pointer items-center justify-center rounded bg-orange-500 px-4 py-2 text-center text-sm font-bold uppercase leading-none text-white shadow-sm hover:bg-orange-600">
                  <font-awesome-icon icon="external-link-alt" />
                  <span class="ml-2 align-middle">Accéder à la session</span>
                </div>
                <div
                  v-if="
                    inscription.sessionUrlParticipant &&
                    inscription.sessionUrlParticipant.length === 1
                  "
                  v-on:click.stop="
                    () => goToUrl(inscription.sessionUrlParticipant[0].url)
                  "
                  class="my-2 flex w-72 cursor-pointer items-center justify-center rounded bg-orange-500 px-4 py-2 text-center text-sm font-bold uppercase leading-none text-white shadow-sm hover:bg-orange-600">
                  <font-awesome-icon icon="external-link-alt" />
                  <span class="ml-2 align-middle">Accéder à la session</span>
                </div>
                <div
                  v-if="
                    inscription.moduleElearning &&
                    inscription.moduleElearning.accessible
                  "
                  v-on:click.stop="openElearningModule"
                  class="my-2 flex w-72 cursor-pointer items-center justify-center rounded bg-orange-500 px-4 py-2 text-center text-sm font-bold uppercase leading-none text-white shadow-sm hover:bg-orange-600">
                  <font-awesome-icon icon="external-link-alt" />
                  <span class="ml-2 align-middle">Accéder au module</span>
                </div>
                <div
                  v-if="
                    has_formateur_mixte_elearning &&
                    !has_formateur_mixte_elearning.disabled
                  "
                  v-on:click.stop="openFormateurElearningModule"
                  class="my-2 flex w-72 cursor-pointer items-center justify-center rounded bg-orange-500 px-4 py-2 text-center text-sm font-bold uppercase leading-none text-white shadow-sm hover:bg-orange-600">
                  <font-awesome-icon icon="external-link-alt" />
                  <span class="ml-2 align-middle">Accéder au module</span>
                </div>
                <div
                  v-if="
                    inscription.sessionUrlParticipant &&
                    inscription.sessionUrlParticipant.length > 1
                  "
                  v-on:click.stop="openConnexionModal"
                  class="my-2 flex w-72 cursor-pointer items-center justify-center rounded bg-orange-500 px-4 py-2 text-center text-sm font-bold uppercase leading-none text-white shadow-sm hover:bg-orange-600">
                  <font-awesome-icon icon="external-link-alt" />
                  <span class="ml-2 align-middle">
                    Informations de connexion
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="description__right">
            <div>
              <div
                v-if="isFormateur && isFormateurStatusCancel"
                class="mb-3 flex items-center justify-center space-x-2 rounded bg-jinius-red px-3 py-2 leading-none text-core-100 shadow">
                <font-awesome-icon icon="ban" />
                <span class="px-0.5"> Session annulée </span>
              </div>
              <div
                v-else-if="isParticipant && isSessionStatusCancel"
                class="mb-3 flex items-center justify-center space-x-2 rounded bg-jinius-red px-3 py-2 leading-none text-core-100 shadow">
                <font-awesome-icon icon="ban" />
                <span class="px-0.5"> Session annulée </span>
              </div>
              <div
                v-else-if="inscription.participantStatut && !parcours"
                class="mb-3 flex items-center justify-center space-x-2 rounded px-3 py-2 leading-none text-core-100 shadow"
                v-bind:class="
                  map_statut_inscription_colors[getParticipantStatusKey]
                    ? map_statut_inscription_colors[getParticipantStatusKey]
                        .color
                    : map_statut_inscription_colors['isPending']
                ">
                <font-awesome-icon
                  v-if="map_statut_inscription_colors[getParticipantStatusKey]"
                  v-bind:icon="
                    map_statut_inscription_colors[getParticipantStatusKey].icon
                  "
                  size="sm" />
                <span class="px-0.5">
                  {{
                    inscription.participantStatut.elearningParticipant
                      ? map_statut_inscription_elearning_labels[
                          inscription.participantStatut.id
                        ]
                      : map_statut_inscription_labels[
                          inscription.participantStatut.id
                        ]
                  }}
                </span>
              </div>
            </div>

            <div>
              <div
                v-on:click="goToDetails"
                v-if="inscription.accesDetails"
                class="flex flex-grow cursor-pointer flex-wrap items-center justify-end text-base text-core-500 hover:text-core-600">
                <font-awesome-icon icon="search" />
                <span class="ml-2">Afficher les détails de la session</span>
              </div>
            </div>

            <div>
              <div
                v-on:click.stop="openPositionnement"
                v-if="
                  isParticipant &&
                  inscription.testPositionnementDisponible &&
                  !inscription.testPositionnementRealise
                "
                class="inline-flex cursor-pointer items-center justify-center space-x-3 rounded bg-core-200 px-4 py-2 text-base leading-tight text-primary-700 hover:bg-core-100 hover:text-primary-900">
                <font-awesome-icon icon="pen" />
                <span class="whitespace-nowrap">
                  Répondre au test de positionnement
                </span>
              </div>
            </div>

            <div>
              <div
                v-if="
                  inscription.catalogueReference &&
                  inscription.catalogueUrl &&
                  !produit
                "
                v-on:click="
                  () =>
                    goToFiche(
                      inscription.catalogueReference,
                      inscription.catalogueUrl
                    )
                "
                class="mt-4 inline-flex cursor-pointer flex-wrap items-center justify-end text-base text-core-500 hover:text-core-600">
                <font-awesome-icon icon="eye" />
                <span class="ml-2.5">Voir la fiche pédagogique</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <participants-modal
      v-if="
        inscription.participants &&
        inscription.participants.length > participants_number_to_display
      "
      v-bind:modal_show="participants_modal"
      v-bind:onclose="closeParticipantsModal"
      v-bind:participants="inscription.participants" />

    <connexion-modal
      v-bind:modal_show="connexion_modal"
      v-on:onclose="closeConnexionModal"
      v-if="
        inscription.sessionUrlParticipant &&
        inscription.sessionUrlParticipant.length > 0
      "
      v-bind:session_url="inscription.sessionUrlParticipant" />
  </div>
</template>

<script>
  import { convertStringToDate } from '@/assets/data/ag_grid_fields_map';
  import {
    map_statut_inscription_colors,
    map_statut_inscription_elearning_labels,
    map_statut_inscription_labels,
    map_type_colors
  } from '@/assets/data/data_maps.js';
  import { API } from '@/http-common';
  import dates_helpers from '@/mixin/dates_helpers';
  import http_functions from '@/mixin/http_functions';
  import ConnexionModal from '@/views/_espace/connexion-modal';
  import participantsModal from '@/views/_espace/_entreprise/_sessions/_modal/participants-modal';

  export default {
    components: {
      participantsModal,
      ConnexionModal
    },
    mixins: [dates_helpers, http_functions],
    props: {
      inscription: {
        type: Object,
        default: null
      },
      parcours: {
        type: Boolean,
        default: false
      },
      collapsed: {
        type: Boolean,
        default: null
      },
      toggle_collapse: {
        type: Function,
        default: null
      },
      espace: { type: String },
      produit: {
        type: Boolean,
        default: false
      }
    },
    emits: ['open-positionnement'],
    data() {
      return {
        connexion_modal: false,
        map_type_colors,
        map_statut_inscription_colors,
        map_statut_inscription_labels,
        map_statut_inscription_elearning_labels,
        participants_number_to_display: 5,
        participants_modal: false,
        has_formateur_mixte_elearning: null
      };
    },
    computed: {
      isParticipant() {
        return this.espace === 'participant';
      },
      isFormateur() {
        return this.espace === 'formateur';
      },
      isSessionStatusCancel() {
        return this.inscription?.sessionStatut?.id === 5;
      },
      isFormateurStatusCancel() {
        return this.inscription?.formateurStatut?.id === 3;
      },
      getParticipantStatusKey() {
        const key = ['isValid', 'isNotValid', 'isPending'].find(
          (key) => this.inscription.participantStatut[key] === true
        );

        return key;
      }
    },
    async created() {
      if (this.isFormateur) {
        try {
          const res = await API.get(
            `/formateur/formation/${this.inscription.reference}/elearning`,
            this.$header_skip_redirection
          );

          if (res && res.data) {
            this.has_formateur_mixte_elearning = res.data;
          }
        } catch (e) {
          return false;
        }
      }
    },
    methods: {
      openConnexionModal() {
        this.connexion_modal = true;
      },
      closeConnexionModal() {
        this.connexion_modal = false;
      },
      goToUrl(url) {
        window.open(this.formatUrlProtocol(url), '_blank');
      },
      async openElearningModule() {
        const { commandeId, reference } = this.inscription;

        if (commandeId && reference) {
          const response = await API.get(
            `participant/commande/${commandeId}/formation/${reference}/elearning`,
            this.$header_skip_redirection
          );

          if (response && response.data && response.data.url) {
            window.open(response.data.url);
          }
        }
      },
      async openFormateurElearningModule() {
        if (this.has_formateur_mixte_elearning?.url) {
          window.open(this.has_formateur_mixte_elearning.url);
        }
      },
      type_anchor_color(id) {
        return `border-${map_type_colors[id]}`;
      },
      type_bg_color(id) {
        return `bg-${map_type_colors[id]}`;
      },
      type_text_color(id) {
        return `text-${map_type_colors[id]}`;
      },
      goToDetails() {
        const { reference, commandeId } = this.inscription;

        if (this.espace === 'formateur' && reference) {
          this.$router.push(`/${this.espace}/details?reference=${reference}`);
        } else if (reference && commandeId) {
          this.$router.push(
            `/${this.espace}/details?reference=${reference}&commande=${commandeId}`
          );
        }
      },
      goToFiche(reference, url) {
        if (reference && url) {
          window.open(`${url}/fiche/${reference}`);
        }
      },
      displayDates(dates) {
        const sortedDates = dates
          .slice()
          .sort(
            (a, b) =>
              convertStringToDate(a.date).getTime() -
              convertStringToDate(b.date).getTime()
          );

        return sortedDates.map(
          (date) =>
            `${date.date}${date.matin && !date.aprem ? ' (matin)' : ''}${!date.matin && date.aprem ? ' (après-midi)' : ''}`
        );
      },
      displayParticipants(participants) {
        return participants
          .slice(0, this.participants_number_to_display)
          .map(
            (participant) =>
              `${participant.prenom && participant.nom ? `${participant.prenom} ${participant.nom}` : 'Réservation'} ${participant.statut ? `(${participant.statut.label})` : ''}`
          );
      },
      cardAction() {
        if (this.toggle_collapse) {
          this.toggle_collapse();
        } else {
          const is_detail_available =
            (this.inscription.accesDetails &&
              !this.parcours &&
              !this.isSessionStatusCancel) ||
            (!this.isFormateurStatusCancel && !this.isParticipant);

          is_detail_available ? this.goToDetails() : null;
        }
      },
      openParticipantsModal(e) {
        e.stopPropagation();
        if (
          this.inscription.participants.length >
          this.participants_number_to_display
        ) {
          this.participants_modal = true;
        }
      },
      closeParticipantsModal() {
        this.participants_modal = false;
      },
      openPositionnement() {
        this.$emit('open-positionnement', this.inscription);
      }
    }
  };
</script>

<style lang="scss" scoped>
  @use '@/assets/css/utils/mixins' as mixin;

  .inscription-card {
    @apply mb-4 flex overflow-hidden rounded-lg bg-white shadow transition;

    &.inscription-card--head-list {
      @apply shadow-lg;
      &:hover .content__title {
        @apply text-primary-700;
      }
    }

    &.inscription-card-disabled {
      @apply cursor-not-allowed text-accent-500 hover:bg-core-200 hover:text-accent-400 hover:shadow-sm;
    }

    .card__container {
      @apply relative flex w-full items-center;

      .card__container--anchor {
        @apply absolute left-0 top-0 z-10 h-10 w-12 border-l-8 border-t-8;
      }

      .container__content {
        @apply relative h-full w-full py-5 pl-4 pr-3 tracking-tight shadow;

        .content__type {
          @apply absolute right-0 top-0 mr-2 mt-2 flex space-x-2;
          .type__label {
            @apply rounded-full px-3 py-0.5 text-xs font-bold lowercase text-core-100 shadow first-letter:capitalize;
          }
        }

        .content__title {
          @apply mb-4 text-xl font-bold leading-tight;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .content__title__legend {
            @apply ml-2 inline-flex cursor-pointer items-center space-x-2 text-sm font-normal leading-none text-core-700 text-transparent transition;
            .title__legend__icon,
            .title__legend__text {
              @apply transition ease-in;
            }
          }
        }

        .content__reference {
          @apply mb-2 text-accent-500;
        }

        .content__description {
          @apply mt-6 flex flex-wrap items-end justify-between text-sm md:mt-4 md:flex-nowrap;

          .description__left {
            @apply w-full flex-grow self-start pl-5 pr-3;
          }

          .description__right {
            @apply mt-6 flex w-full flex-wrap px-4 md:w-120 md:justify-end;
            > div {
              @apply flex w-full justify-end;
            }
          }
        }
      }
    }
  }
</style>
