<template>
  <div class="formation-card group">
    <div class="card__container">
      <div
        class="card__container--anchor"
        v-bind:class="type_anchor_color(formation.type[0].id)"></div>

      <div class="container__content">
        <div class="content__type">
          <div
            v-for="{ id, label } in formation.type"
            v-bind:key="id"
            class="type__label"
            v-bind:class="type_bg_color(id)">
            <span>{{ label }}</span>
          </div>
        </div>

        <div class="content__description">
          <div class="description__left">
            <div class="content__title">
              <span class="content__title__text">
                <span data_id="titre">
                  {{ `${formation.titre}` }}
                </span>
              </span>
            </div>

            <div
              v-if="formation.reference"
              class="content__reference">
              Réf&nbsp;:
              <span
                data_id="reference"
                class="font-bold">
                {{ formation.reference }}
              </span>
            </div>
            <div class="flex flex-col space-y-1">
              <div
                v-if="formation.lieu"
                class="flex items-center">
                <span
                  class="inline-flex w-4 items-center justify-center align-middle">
                  <font-awesome-icon
                    icon="map-marker-alt"
                    size="1x" />
                </span>
                <span class="ml-2.5 mr-1.5">
                  <em class="text-accent-500">Lieu&nbsp;: </em>
                  <span data_id="lieu">
                    {{ formation.lieu }}
                  </span>
                </span>
                <span
                  v-if="formation.mode && formation.mode.id === 2"
                  class="bg-jinius-dark-green px-1.5 py-0.5 align-text-top text-xs leading-none text-white shadow-sm">
                  {{ formation.mode.label }}
                </span>
              </div>

              <div
                v-if="formation.sessionDateDebut && formation.sessionDateFin">
                <span
                  class="inline-flex w-4 items-center justify-center align-middle">
                  <font-awesome-icon
                    v-bind:icon="['far', 'calendar-alt']"
                    size="1x" />
                </span>
                <span class="mx-2">
                  {{
                    `Du ${formation.sessionDateDebut} au ${formation.sessionDateDebut}`
                  }}
                </span>
              </div>

              <div
                v-if="formation.dureeMinutes"
                class="flex items-center space-x-1.5">
                <font-awesome-icon v-bind:icon="['far', 'clock']" />
                <span>
                  {{ formatMinutesToHours(formation.dureeMinutes) }}&nbsp;
                </span>
              </div>
              <div v-if="formation.organisateur">
                <span
                  class="inline-flex w-4 items-center justify-center align-middle">
                  <font-awesome-icon
                    icon="tag"
                    size="1x" />
                </span>

                <span class="mx-2 align-middle">
                  <em class="text-accent-500">Organisateur&nbsp;: </em>
                  <span data_id="organisateur">
                    {{ formation.organisateur }}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="description__right">
            <div>
              <div
                v-if="
                  formation.formationLibreTypeId &&
                  formation.formationLibreTypeId === 2
                "
                class="mb-3 flex items-center justify-center space-x-2 rounded bg-purple-600 px-3 py-2 leading-none text-core-100 shadow">
                <span class="px-0.5"> Session déléguée </span>
              </div>
            </div>

            <div>
              <div
                class="flex flex-grow flex-wrap items-center justify-end text-base text-core-500"
                v-if="formation.cycleStageCAC">
                {{ formation.cycleStageCAC }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { map_type_colors } from '@/assets/data/data_maps.js';
  import dates_helpers from '@/mixin/dates_helpers';
  import http_functions from '@/mixin/http_functions';

  export default {
    components: {},
    mixins: [dates_helpers, http_functions],
    props: {
      formation: {
        type: Object,
        default: null
      }
    },
    data() {
      return { map_type_colors };
    },
    computed: {},
    methods: {
      type_anchor_color(id) {
        return `border-${map_type_colors[id]}`;
      },
      type_bg_color(id) {
        return `bg-${map_type_colors[id]}`;
      },
      type_text_color(id) {
        return `text-${map_type_colors[id]}`;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @use '@/assets/css/utils/mixins' as mixin;

  .formation-card {
    @apply mb-4 flex overflow-hidden rounded-lg bg-white shadow transition;

    &.formation-card--head-list {
      @apply shadow-lg;
      &:hover .content__title {
        @apply text-primary-700;
      }
    }

    &.formation-card-disabled {
      @apply cursor-not-allowed text-accent-500 hover:bg-core-200 hover:text-accent-400 hover:shadow-sm;
    }

    .card__container {
      @apply relative flex w-full items-center;

      .card__container--anchor {
        @apply absolute left-0 top-0 z-10 h-10 w-12 border-l-8 border-t-8;
      }

      .container__content {
        @apply relative h-full w-full py-5 pl-4 pr-3 tracking-tight shadow;

        .content__type {
          @apply absolute right-0 top-0 mr-2 mt-2 flex space-x-2;
          .type__label {
            @apply rounded-full px-3 py-0.5 text-xs font-bold lowercase text-core-100 shadow first-letter:capitalize;
          }
        }

        .content__title {
          @apply mb-4 text-xl font-bold leading-tight;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .content__title__legend {
            @apply ml-2 inline-flex cursor-pointer items-center space-x-2 text-sm font-normal leading-none text-core-700 text-transparent transition;
            .title__legend__icon,
            .title__legend__text {
              @apply transition ease-in;
            }
          }
        }

        .content__reference {
          @apply mb-2 text-accent-500;
        }

        .content__description {
          @apply mt-6 flex flex-wrap items-end justify-between text-sm md:mt-4 md:flex-nowrap;

          .description__left {
            @apply w-full flex-grow self-start pl-5 pr-3;
          }

          .description__right {
            @apply mt-6 flex w-full flex-wrap px-4 md:w-120 md:justify-end;
            > div {
              @apply flex w-full justify-end;
            }
          }
        }
      }
    }
  }
</style>
