<template>
  <div class="details__container">
    <spinner
      v-if="loading"
      sizes="w-20 h-20"
      thickness="border-8" />

    <div
      v-if="!loading"
      class="h-full">
      <router-link
        v-bind:to="`/${back_base_url}/${!session.terminee ? 'sessions' : 'sessionsdone'}`"
        class="inline-flex rounded-lg bg-white px-3 pb-1 pt-1.5 text-sm font-bold uppercase leading-none shadow-sm transition duration-150 ease-out hover:text-primary-800 hover:shadow focus:text-primary-800">
        <font-awesome-icon
          class="mr-2"
          icon="chevron-circle-left"
          size="1x" />
        <span class="btn-back__text">Retour aux sessions</span>
      </router-link>

      <div
        class="mb-8 flex w-full flex-wrap items-center lg:mb-0 lg:flex-nowrap">
        <h1 class="section__title mb-6 mt-0">Détails de la session</h1>

        <div
          v-if="session.mentionsAccessibilite"
          class="my-4 mr-8 sm:my-0">
          <display-accessibilite-icons
            v-bind:mentions_accessibilite="session.mentionsAccessibilite" />
        </div>

        <div
          v-on:click="onOpenModalContact"
          class="flex cursor-pointer items-center whitespace-nowrap rounded bg-jinius-dark-green px-6 py-2.5 font-bold leading-none text-white shadow hover:bg-opacity-90">
          <font-awesome-icon
            icon="address-book"
            size="sm" />
          <span class="ml-3">Contacter l'organisateur</span>
        </div>
      </div>

      <div class="flex flex-col justify-center lg:flex-row">
        <div class="w-full lg:mr-2 lg:w-1/2">
          <session-card
            v-if="session"
            v-bind:open_elearning_question_modal="openElearningQuestionModal"
            v-bind:head_list="session.produits.length > 0"
            v-bind:participant="participant"
            v-bind:session="{
              ...session,
              type: getTypeList(
                session.classeVirtuelle,
                session.type,
                session.ficheType
              )
            }" />

          <div
            v-if="isParcoursOrCursus"
            class="-mt-3 ml-2 border-l border-accent-300 pl-4 pt-4 lg:ml-6 lg:pl-6">
            <session-card
              v-for="produit in session.produits"
              v-bind:key="`produit-${produit.reference}`"
              v-bind:session="{
                ...produit,
                type: getTypeList(produit.classeVirtuelle, produit.type),
                lieuNom:
                  produit.type &&
                  produit.type.id === 1 &&
                  session.sessionLieu &&
                  session.sessionLieu.nom
              }"
              v-bind:go_to_details="go_to_details"
              v-bind:parcours="true" />
          </div>

          <div
            v-if="session.site"
            class="mb-3 flex items-center rounded-lg bg-white p-6 shadow">
            <div class="mr-4">
              <font-awesome-icon
                class="text-core-500"
                icon="map-marker-alt"
                size="3x" />
            </div>
            <div class="flex-grow">
              <div
                v-if="session.site.denomination"
                class="font-bold uppercase">
                {{ session.site.denomination }}
              </div>
              <div
                v-if="session.site.numeroVoie"
                class="text-sm uppercase">
                {{ session.site.numeroVoie }}
              </div>
              <div
                v-if="session.site.codePostal && session.site.ville"
                class="text-sm uppercase">
                {{ `${session.site.codePostal} ${session.site.ville}` }}
              </div>
            </div>
            <div
              v-if="session.site.coordinates"
              v-on:click="openMapModal"
              v-tooltip.top="`Consulter l'emplacement sur une carte dynamique`"
              class="group flex h-16 w-16 cursor-pointer items-center justify-center rounded-full bg-jinius-blue shadow-sm transition-colors hover:bg-jinius-dark-green">
              <font-awesome-icon
                class="pb-1 text-white"
                icon="map-marked-alt"
                size="2x" />
            </div>
          </div>

          <div class="flex flex-col items-center sm:flex-row sm:space-x-4">
            <div
              v-if="session.satisfaction"
              class="mb-3 w-full sm:w-1/2">
              <div
                v-on:click="openEvaluationModal(session)"
                class="flex h-full w-full flex-col items-center justify-center rounded-lg bg-yellow-100 p-4 text-center text-lg shadow transition"
                v-bind:class="{
                  'cursor-pointer hover:bg-yellow-200':
                    !session.satisfaction.dateReponse && session.satisfaction.id
                }">
                <div
                  class="mb-3 text-sm font-bold leading-none text-core-700 xl:text-lg xl:leading-none">
                  Satisfaction
                </div>

                <template v-if="session.satisfaction.expire">
                  <div
                    class="flex flex-grow items-center text-lg font-bold leading-none text-blue-900">
                    Non réalisé
                  </div>
                </template>

                <div
                  v-if="session.satisfaction.dateReponse"
                  class="flex flex-grow items-center text-lg font-bold leading-none text-yellow-600">
                  Réalisé le : {{ session.satisfaction.dateReponse }}
                </div>

                <div
                  v-else-if="session.satisfaction.id"
                  class="flex flex-grow items-center text-lg font-bold leading-none text-yellow-600">
                  <font-awesome-icon
                    icon="pen"
                    size="sm" />
                  <span class="mx-3">Saisir ma satisfaction</span>
                </div>
              </div>
            </div>
            <div
              v-if="
                session.examen &&
                (session.examen.id ||
                  session.examen.expire ||
                  session.examen.repondu)
              "
              class="mb-3 w-full sm:w-1/2">
              <div
                v-on:click="openExamenModal(session)"
                class="flex h-full w-full flex-col items-center justify-center rounded-lg bg-orange-100 p-4 text-center text-lg shadow transition"
                v-bind:class="{
                  'cursor-pointer hover:bg-orange-200':
                    session.examen.id && !session.examen.termine
                }">
                <div
                  class="mb-3 text-sm font-bold leading-none text-core-700 xl:text-lg xl:leading-none">
                  Examen
                </div>

                <template v-if="session.examen.expire">
                  <div
                    class="flex flex-grow items-center text-lg font-bold leading-none text-blue-900">
                    Non réalisé
                  </div>
                </template>
                <div
                  v-else-if="session.examen.id && !session.examen.termine"
                  class="flex flex-grow items-center text-lg font-bold leading-none text-orange-600">
                  <font-awesome-icon
                    icon="pen"
                    size="sm" />
                  <span class="mx-3">{{
                    session.examen.reponsesJson
                      ? 'Reprendre mon examen'
                      : 'Commencer mon examen'
                  }}</span>
                </div>
                <template
                  v-else-if="session.examen.repondu && session.examen.termine">
                  <div
                    class="flex flex-grow items-center text-lg font-bold leading-none text-blue-900">
                    <span>Note&nbsp;:&nbsp;</span>
                    <span class="tracking-wider">
                      {{ session.examen.nbreBonnesResponses }}/{{
                        session.examen.nbreQuestions
                      }}
                    </span>
                  </div>
                </template>
              </div>
            </div>
            <div
              class="mb-3 w-full sm:w-1/2"
              v-if="
                session.testValidation &&
                (session.testValidation.id || session.testValidation.repondu)
              ">
              <div
                v-on:click="openValidationAcquisModal(session)"
                class="flex h-full w-full flex-col items-center justify-center rounded-lg bg-blue-100 p-4 text-center text-lg shadow transition"
                v-bind:class="{
                  'cursor-pointer hover:bg-blue-200':
                    isTestValidationOuvrable(session)
                }">
                <div
                  v-if="
                    session.parcoursTestValidationAcquisSousProduits &&
                    session.testValidation.testIntermediaire
                  "
                  class="mb-3 text-sm font-bold leading-none text-core-700 xl:text-lg xl:leading-none">
                  Test intermédiaire
                </div>
                <div
                  v-else
                  class="mb-3 text-sm font-bold leading-none text-core-700 xl:text-lg xl:leading-none">
                  Validation des acquis
                  {{
                    session.testValidation.minBonnesReponses &&
                    session.testValidation.nbreQuestions &&
                    `(Seuil : ${session.testValidation.minBonnesReponses}/${session.testValidation.nbreQuestions})`
                  }}
                </div>

                <!-- <template v-if="session.testValidation.expire">
                  <div class="flex-grow flex items-center text-blue-900 text-lg font-bold leading-none">
                    Non réalisé
                  </div>
                </template> -->

                <template
                  v-if="
                    session.testValidation.id && !session.testValidation.repondu
                  ">
                  <div
                    class="flex flex-grow items-center text-lg font-bold leading-none text-blue-900">
                    <font-awesome-icon
                      icon="pen"
                      size="sm" />
                    <span class="mx-3">Commencer mon test</span>
                  </div>
                  <div
                    v-if="
                      isNewRuleStarted &&
                      session.attestationDureeJoursGeneration &&
                      !(
                        session.parcoursTestValidationAcquisSousProduits &&
                        session.testValidation.testIntermediaire
                      )
                    "
                    class="mt-2.5 space-y-1 rounded-sm border border-blue-300 px-2 py-1 text-left text-xs/tight">
                    <div>
                      À l'issue de la session, si vous réalisez votre test au
                      delà de {{ session.attestationDureeJoursGeneration }}j
                      l'attestation de formation sera automatiquement générée
                      avec la mention
                      <strong
                        >"Résultat de l'évaluation des acquis: non
                        réalisé"</strong
                      >.
                    </div>
                    <div>
                      Au delà de ce délai vous pouvez réaliser le test à titre
                      d'auto évaluation mais l'attestation de formation ne sera
                      pas mise à jour automatiquement.
                    </div>
                  </div>
                </template>
                <template
                  v-if="
                    isTestValidationOuvrable(session) &&
                    session.testValidation.repondu
                  ">
                  <div
                    class="flex flex-grow items-center text-lg font-bold leading-none text-blue-900">
                    <font-awesome-icon
                      icon="pen"
                      size="sm" />
                    <span class="mx-3"
                      >Repasser mon test ({{
                        `${nbre_tentatives_restantes} ${nbre_tentatives_restantes > 1 ? 'tentatives restantes' : 'tentative restante'}`
                      }})</span
                    >
                  </div>
                </template>
                <template v-else-if="session.testValidation.repondu">
                  <div
                    class="flex flex-grow items-center text-lg font-bold leading-none text-blue-900">
                    <span>Note&nbsp;:&nbsp;</span>
                    <span class="tracking-wider">
                      {{ session.testValidation.nbreBonnesResponses }}/{{
                        session.testValidation.nbreQuestions
                      }}
                    </span>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <div
            v-if="
              session.type && session.type.id === 2 && session.moduleElearning
            "
            class="mb-3 flex flex-wrap rounded-lg bg-white p-6 shadow">
            <h2
              class="mb-8 mt-0 flex w-full border-l-4 border-jinius-green pl-6 text-3xl font-bold leading-none text-core-700">
              Module e-learning
              <span
                v-if="session.moduleElearning.statutString"
                class="ml-2 text-lg font-normal italic text-core-500">
                {{ `(${session.moduleElearning.statutString})` }}</span
              >
            </h2>

            <template
              v-if="
                session.type &&
                session.type.id === 2 &&
                session.moduleElearning &&
                session.moduleElearning.accessible
              ">
              <message-box
                v-if="session.moduleElearning.showRetractation"
                v-bind:source="`<p class='mb-1'>En tant que particulier, <strong>vous bénéficiez d'un droit de rétractation de 14 jours.</strong></p> <p>Pour accéder immédiatement à votre module e-learning avant la fin de la période de rétractation, vous avez la possibilité de renoncer à votre droit de rétractation en cliquant sur le bouton <span class='italic px-0.5'>Accéder à la formation</span>.</p><p>Si vous souhaitez utiliser ce droit, contactez l'organisateur à l'adresse suivante <a href='mailto:${session.organisateur.email}'>${session.organisateur.email}</p>`"
                border_color="w-full border-jinius-blue" />

              <div
                v-on:click="() => openElearningModule(session.moduleElearning)"
                class="my-4 flex h-12 cursor-pointer items-center justify-center space-x-3 rounded bg-orange-500 px-6 text-center text-lg font-bold uppercase leading-none text-white shadow-md hover:bg-orange-600">
                <font-awesome-icon
                  icon="external-link-alt"
                  size="sm" />
                <span>Accéder à la formation</span>
              </div>
            </template>
            <message-box
              v-if="!(pif_signee || pif_uploaded) && pif_disponible"
              v-bind:source="
                session.inscriptionAutomatiqueLmsPif
                  ? `<strong><span class='text-jinius-red'>Pour accéder au module e-learning,</span> veuillez télécharger, signer et téléverser votre PIF</strong> (Protocole Individuel de Formation).`
                  : `<strong>Veuillez télécharger, signer et téléverser votre PIF</strong> (Protocole Individuel de Formation).`
              "
              border_color="w-full border-jinius-red" />

            <div
              v-if="session.moduleElearning && session.moduleElearning.expire"
              class="mx-2 mb-4 flex w-full font-bold italic leading-none text-core-500">
              La durée d'accessibilité du module e-learning est dépassée. Le
              module n'est plus accessible.
            </div>
            <div
              v-for="(cours, index) in session.moduleElearning.cours"
              v-bind:key="`cours-${index}`"
              class="flex w-full space-x-4 p-3">
              <div
                class="flex w-1/12 justify-center align-middle text-xl font-bold leading-none text-jinius-green">
                <font-awesome-icon
                  size="2x"
                  icon="book-open" />
              </div>
              <div class="flex w-11/12 flex-wrap">
                <div
                  class="mb-0.5 w-full break-all text-xl font-bold leading-none text-core-500">
                  {{ cours.titre }}
                </div>
                <div class="flex w-full">
                  {{ cours.statutString }}
                  {{ cours.score !== '0' ? cours.score + '%' : '' }}
                </div>
              </div>
            </div>
            <div
              v-if="session.moduleElearning && session.moduleElearning.expire"
              class="flex flex-grow flex-wrap items-center justify-end">
              <div
                class="rounded bg-jinius-red px-3 py-0.5 text-center text-sm font-bold uppercase text-white shadow-md">
                <span class="align-middle"> Expiré </span>
              </div>
            </div>
          </div>

          <div
            v-if="
              ((sessionUrl && sessionUrl.length) ||
                session.sessionUrlWebinaire) &&
              !session.programmes
            "
            class="mb-3 flex flex-wrap rounded-lg bg-white p-6 shadow">
            <h2
              class="mb-6 mt-0 flex w-full border-l-4 border-jinius-green pl-6 text-3xl font-bold leading-none text-core-700">
              Connexion
            </h2>
            <div
              v-on:click="openConnexionModal"
              v-if="sessionUrl && sessionUrl.length > 1"
              class="mb-1 ml-2 flex h-8 cursor-pointer rounded bg-orange-500 px-4 py-2 text-center text-sm font-bold uppercase text-white shadow-md hover:bg-orange-600">
              <font-awesome-icon icon="external-link-alt" />
              <span class="ml-2 align-middle">Informations de connexion</span>
            </div>
            <div
              v-on:click="() => goToUrl(sessionUrl[0].url)"
              v-if="sessionUrl && sessionUrl.length === 1"
              class="mb-1 ml-2 flex h-8 cursor-pointer rounded bg-orange-500 px-4 py-2 text-center text-sm font-bold uppercase text-white shadow-md hover:bg-orange-600">
              <font-awesome-icon icon="external-link-alt" />
              <span class="ml-2 align-middle">Accéder à la session</span>
            </div>
            <div
              v-on:click="() => goToUrl(session.sessionUrlWebinaire)"
              v-if="session.sessionUrlWebinaire"
              class="mb-1 ml-2 flex h-8 cursor-pointer rounded bg-orange-500 px-4 py-2 text-center text-sm font-bold uppercase text-white shadow-md hover:bg-orange-600">
              <font-awesome-icon icon="external-link-alt" />
              <span class="ml-2 align-middle">Accéder à la session</span>
            </div>
          </div>

          <div
            v-if="session.seances"
            class="mb-3 rounded-lg bg-white p-6 shadow">
            <h2
              class="mb-6 mt-2 border-l-4 border-jinius-green pl-3 text-2xl font-bold leading-none text-core-700">
              Séances
            </h2>
            <seance-description-card
              v-for="seance in session.seances"
              v-bind:key="seance.id"
              v-bind:seance="seance" />
          </div>

          <div
            v-if="session.programmes"
            class="mb-3 rounded-lg bg-white p-6 shadow">
            <h2
              class="mb-6 mt-2 border-l-4 border-jinius-green pl-3 text-2xl font-bold leading-none text-core-700">
              Programme
            </h2>
            <programme-list
              v-bind:programme="session.programmes"
              v-bind:classe_virtuelle="
                session.classeVirtuelle ||
                (session.type && [2, 3, 5].includes(session.type.id))
              "
              v-bind:is_formateur="formateur"
              v-bind:session_url_webinaire="session.sessionUrlWebinaire"
              v-bind:session_url="sessionUrl"
              v-bind:open_connexion_modal="openConnexionModal" />
          </div>
        </div>
        <div class="w-full lg:ml-2 lg:w-1/2">
          <div
            class="mb-3 rounded-lg bg-white p-6 shadow"
            v-if="
              session.certificat ||
              pif_signee ||
              pif_disponible ||
              convention_signee ||
              convention_disponible ||
              (session.support && !session.support.disabled) ||
              session.convocation ||
              session.factures ||
              session.attestation ||
              (session.documents && session.documents.length) ||
              (session.presences && session.presences.length)
            ">
            <h2
              class="mb-6 mt-0 border-l-4 border-jinius-green pl-3 text-2xl font-bold leading-none text-core-700">
              Ressources
            </h2>
            <div class="lg:mb-6 lg:ml-8">
              <div
                v-if="!entreprise && session.convocation"
                v-on:click="openConvocationModal"
                class="ressources__download__link__container">
                <h3 class="ressources__download__link__label">
                  Ma convocation
                </h3>
                <div class="ressources__download__link__button">
                  <div class="ressources__download__link__button__content">
                    <font-awesome-icon
                      class="text-xl"
                      icon="eye" />
                  </div>
                </div>
              </div>

              <div
                v-if="
                  formateur &&
                  session.presences &&
                  session.presences.length === 1
                "
                class="ressources__download__link__container"
                v-on:click="downloadFeuillePresence(session.presences[0])">
                <h3 class="ressources__download__link__label">
                  Feuille de présence de la session
                </h3>
                <div class="ressources__download__link__button">
                  <div class="ressources__download__link__button__content">
                    <font-awesome-icon
                      class="text-xl"
                      icon="download" />
                  </div>
                </div>
              </div>
              <div
                v-else-if="
                  formateur && session.presences && session.presences.length > 1
                "
                class="mb-3 ml-8 flex w-full flex-wrap items-center">
                <div class="flex w-full">
                  <h3 class="mb-2 text-xl font-bold leading-none text-core-700">
                    Feuille de présence de la session
                  </h3>
                </div>
                <div class="ml-4 flex w-full">
                  <ul class="list-inside list-disc">
                    <li
                      v-for="(presence, index) in presences"
                      v-bind:key="presence.seanceId"
                      class="ressources__download__link__container flex w-full"
                      v-on:click="downloadFeuillePresence(presence)">
                      <div class="ressources__download__link__label text-xs">
                        {{
                          `- Séance-${index + 1}${presence.nom ? ` : ${presence.nom}` : ''}`
                        }}
                      </div>
                      <div class="ressources__download__link__button">
                        <div
                          class="ressources__download__link__button__content">
                          <font-awesome-icon
                            class="text-xl"
                            icon="download" />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                v-if="
                  (formateur || participant) &&
                  session.attestation &&
                  session.attestation.url
                "
                class="ressources__download__link__container"
                v-on:click="
                  downloadAttestation(
                    session.attestation.url,
                    session.attestation.nom
                  )
                ">
                <h3
                  v-if="participant"
                  class="ressources__download__link__label">
                  Mon attestation de formation
                </h3>
                <h3
                  v-else-if="formateur"
                  class="ressources__download__link__label">
                  Mon attestation d'animation
                </h3>
                <div class="ressources__download__link__button">
                  <div class="ressources__download__link__button__content">
                    <font-awesome-icon
                      class="text-xl"
                      icon="download" />
                  </div>
                </div>
              </div>

              <div
                class="mb-2 flex w-full justify-around"
                v-if="
                  entreprise && session.factures && session.factures.length
                ">
                <div class="mt-2 flex w-full">
                  <h3 class="ml-8 text-xl font-bold leading-none text-core-700">
                    Mes factures
                  </h3>
                </div>
              </div>
              <div
                v-if="entreprise && session.factures && session.factures.length"
                class="mb-6">
                <ag-grid-wrapper
                  v-bind:column_defs="[
                    {
                      headerName: 'Nom',
                      field: 'nom',
                      cellRenderer: 'supportTitreCellRenderer',
                      filter: false,
                      floatingFilter: false,
                      minWidth: 150
                    },
                    {
                      headerName: 'Télécharger',
                      field: '',
                      cellRenderer: 'actionCellRenderer',
                      cellRendererParams: {
                        label: 'Télécharger',
                        onclick: (e, params) =>
                          download_facture(params.data?.id, params.data?.nom)
                      }
                    }
                  ]"
                  v-bind:initial_data="session.factures"
                  v-bind:no_filters="true"
                  v-bind:hide_navigation="true" />
              </div>

              <div
                v-if="(entreprise || participant) && convention_signee"
                class="ressources__download__link__container">
                <h3 class="ressources__download__link__label py-10">
                  Ma convention
                </h3>
                <div
                  class="ressources__download__link__button"
                  v-on:click="
                    () =>
                      downloadConvention(
                        session.convention.id,
                        'validee',
                        session.convention.valideeFileName
                      )
                  ">
                  <div
                    class="ressources__standalone_button__content"
                    title="Télécharger la convention signée">
                    <font-awesome-icon
                      class="text-xl"
                      icon="download" />
                  </div>
                </div>
              </div>
              <div
                v-if="
                  (participant || entreprise) &&
                  !convention_signee &&
                  convention_disponible
                "
                class="mb-3 flex w-full flex-wrap items-center">
                <div class="flex w-full">
                  <h3
                    class="mb-3 text-xl font-bold leading-none text-jinius-green">
                    Convention
                  </h3>
                </div>
                <div class="ml-4 flex w-full flex-wrap">
                  <div
                    class="ressources__download__link__container flex w-full">
                    <h3 class="ressources__download__link__label">
                      - Convention à signer
                    </h3>
                    <div
                      class="ressources__download__link__button"
                      v-on:click="
                        () =>
                          downloadConvention(
                            session.convention.id,
                            '',
                            session.convention.fileName
                          )
                      ">
                      <div
                        class="ressources__standalone_button__content"
                        title="Télécharger la convention à signer">
                        <font-awesome-icon
                          class="text-xl"
                          icon="download" />
                      </div>
                    </div>
                  </div>
                  <div
                    class="ressources__download__link__container flex w-full">
                    <h3 class="ressources__download__link__label">
                      - Convention signée
                      <span class="text-sm font-normal italic"
                        >(par le client)</span
                      >
                    </h3>
                    <div
                      class="ressources__download__link__button"
                      v-on:click="
                        () =>
                          downloadConvention(
                            session.convention.id,
                            'signee',
                            session.convention.signeeFileName
                          )
                      "
                      v-if="
                        !session.convention.signee &&
                        session.convention.fichierSignee
                      ">
                      <div
                        class="ressources__standalone_button__content"
                        title="Télécharger la convention téléversée">
                        <font-awesome-icon
                          class="text-xl"
                          icon="download" />
                      </div>
                    </div>
                    <file-upload
                      v-if="!session.convention.signee"
                      title="Téléverser la convention signée"
                      v-bind:is_file_uploaded="session.convention.fichierSignee"
                      v-bind:upload_url="`${convention_base_url}/${session.convention.id}/file`"
                      v-bind:refresh_session="refresh_session"
                      id="convention-upload" />
                  </div>
                </div>
              </div>

              <div
                v-if="participant && pif_signee"
                class="ressources__download__link__container">
                <h3 class="ressources__download__link__label py-10">Mon PIF</h3>
                <div
                  class="ressources__download__link__button"
                  v-on:click="
                    () =>
                      downloadPIFParticipant(
                        'validee',
                        session.pif.valideeFileName
                      )
                  ">
                  <div
                    class="ressources__standalone_button__content"
                    title="Télécharger mon PIF signé">
                    <font-awesome-icon
                      class="text-xl"
                      icon="download" />
                  </div>
                </div>
              </div>

              <div
                v-if="participant && !pif_signee && pif_disponible"
                class="mb-3 flex w-full flex-wrap items-center">
                <div class="flex w-full">
                  <h3
                    class="mb-3 text-xl font-bold leading-none text-jinius-green">
                    PIF :
                  </h3>
                </div>
                <div class="ml-4 flex w-full flex-wrap">
                  <div
                    class="ressources__download__link__container flex w-full">
                    <h3 class="ressources__download__link__label">
                      - PIF à signer
                    </h3>
                    <div
                      class="ressources__download__link__button"
                      v-on:click="
                        () => downloadPIFParticipant('', session.pif.fileName)
                      ">
                      <div
                        class="ressources__standalone_button__content"
                        title="Télécharger le PIF à signer">
                        <font-awesome-icon
                          class="text-xl"
                          icon="download" />
                      </div>
                    </div>
                  </div>
                  <div
                    class="ressources__download__link__container flex w-full">
                    <h3 class="ressources__download__link__label">
                      - PIF signé
                      <span class="text-sm font-normal italic"
                        >(par le participant et son employeur)</span
                      >
                    </h3>
                    <div
                      class="ressources__download__link__button"
                      v-on:click="
                        () =>
                          downloadPIFParticipant(
                            'signee',
                            session.pif.signeeFileName
                          )
                      "
                      v-if="!session.pif.signee && session.pif.fichierSignee">
                      <div
                        class="ressources__standalone_button__content"
                        title="Télécharger le PIF téléversé">
                        <font-awesome-icon
                          class="text-xl"
                          icon="download" />
                      </div>
                    </div>
                    <file-upload
                      v-if="!session.pif.signee"
                      title="Téléverser le PIF signé"
                      v-bind:is_file_uploaded="session.pif.fichierSignee"
                      v-bind:upload_url="`participant/${session.participantId}/pif/${session.pif.id}/file`"
                      v-bind:refresh_session="refresh_session"
                      id="pif-upload" />
                  </div>
                </div>
              </div>
              <div
                v-if="participant && session.certificat"
                class="ressources__download__link__container"
                v-on:click="
                  () => downloadCertificatParticulier(session.certificat)
                ">
                <h3 class="ressources__download__link__label">
                  Mon certificat de réalisation
                </h3>
                <div class="ressources__download__link__button">
                  <div class="ressources__download__link__button__content">
                    <font-awesome-icon
                      class="text-xl"
                      icon="download" />
                  </div>
                </div>
              </div>
              <div
                class="mb-2 flex w-full justify-around"
                v-if="
                  (participant || formateur) &&
                  session.support &&
                  !session.support.disabled
                ">
                <div class="mt-2 flex w-full">
                  <h3 class="ml-8 text-xl font-bold leading-none text-core-700">
                    Supports
                  </h3>
                </div>
                <div
                  v-if="session.support && session.support.supports.length > 1"
                  class="flex cursor-pointer items-center whitespace-nowrap rounded bg-jinius-dark-green px-6 py-2.5 font-bold leading-none text-white shadow hover:bg-opacity-90"
                  v-on:click="downloadSupports">
                  <font-awesome-icon
                    icon="download"
                    size="sm" />
                  <span class="ml-3"> Tout télécharger (.zip)</span>
                </div>
              </div>
              <div
                v-if="!entreprise && session.support"
                class="mb-6">
                <ag-grid-wrapper
                  v-bind:column_defs="[
                    {
                      headerName: 'Télécharger',
                      field: '',
                      cellRenderer: 'pdfDownloadCellRenderer',
                      cellRendererParams: {
                        session: session,
                        espace: espace,
                        openAppreciationModal: openAppreciationModal
                      }
                    },
                    {
                      headerName: 'Titre',
                      field: 'titre',
                      cellRenderer: 'supportTitreCellRenderer',
                      filter: false,
                      floatingFilter: false,
                      minWidth: 150
                    },
                    {
                      headerName: 'Type',
                      field: 'portailLabel',
                      cellRenderer: 'textCenterCellRenderer'
                    },
                    {
                      headerName: 'Version',
                      field: 'version',
                      cellRenderer: 'textCenterCellRenderer',
                      width: 80
                    },
                    {
                      headerName: 'Mis à jour le',
                      field: 'modifiedDate',
                      cellRenderer: 'textCenterCellRenderer'
                    },
                    {
                      headerName: 'Disponible jusqu\'au',
                      field: 'endDisponibiliteDate',
                      cellRenderer: 'textCenterCellRenderer'
                    }
                  ]"
                  v-bind:initial_data="session.support.supports"
                  v-bind:no_filters="true" />
                <div
                  v-if="
                    session.support.supports.some((s) => s.obsolete) ||
                    session.support.supports.some((s) => s.provisoire)
                  "
                  class="status-label-wrapper">
                  <div
                    v-if="session.support.supports.some((s) => s.provisoire)"
                    class="flex items-center space-x-1">
                    <span class="status-label bg-info-500"></span>
                    <span class="text-xs font-bold">Provisoire</span>
                  </div>

                  <div
                    v-if="session.support.supports.some((s) => s.obsolete)"
                    class="flex items-center space-x-1">
                    <span class="status-label bg-jinius-red"></span>
                    <span class="text-xs font-bold">Obsolète</span>
                  </div>
                </div>
              </div>

              <div
                class="mb-2 flex w-full justify-around"
                v-if="
                  (participant || formateur) &&
                  session.documents &&
                  session.documents.length
                ">
                <div class="mt-2 flex w-full">
                  <h3 class="ml-8 text-xl font-bold leading-none text-core-700">
                    Autres ressources
                  </h3>
                </div>
                <div
                  v-if="session.documents && session.documents.length > 1"
                  class="flex cursor-pointer items-center whitespace-nowrap rounded bg-jinius-dark-green px-6 py-2.5 font-bold leading-none text-white shadow hover:bg-opacity-90"
                  v-on:click="downloadDocuments">
                  <font-awesome-icon
                    icon="download"
                    size="sm" />
                  <span class="ml-3"> Tout télécharger (.zip)</span>
                </div>
              </div>
              <div
                v-if="
                  (participant || formateur) &&
                  session.documents &&
                  session.documents.length
                "
                class="mb-6">
                <ag-grid-wrapper
                  v-bind:column_defs="[
                    {
                      headerName: 'Télécharger',
                      field: '',
                      cellRenderer: 'pdfDownloadCellRenderer',
                      cellRendererParams: {
                        session: session,
                        espace: espace,
                        openAppreciationModal: openAppreciationModal,
                        ignoreDisponibilite: true
                      }
                    },
                    {
                      headerName: 'Titre',
                      field: 'titre',
                      cellRenderer: 'supportTitreCellRenderer',
                      filter: false,
                      floatingFilter: false,
                      minWidth: 150
                    },
                    {
                      headerName: 'Mis à jour le',
                      field: 'modifiedDate',
                      cellRenderer: 'textCenterCellRenderer'
                    }
                  ]"
                  v-bind:initial_data="session.documents"
                  v-bind:no_filters="true" />
              </div>
            </div>
          </div>

          <div
            class="mb-3 rounded-lg bg-white p-6 shadow"
            v-if="
              (session.participants && session.participants.length) ||
              (participant && (note_prerequis || note_validation_acquis))
            ">
            <h2
              class="mb-6 mt-0 border-l-4 border-jinius-green pl-3 text-2xl font-bold leading-none text-core-700">
              {{ participant ? 'Chiffres clés' : 'Participants' }}
            </h2>
            <div class="mb-4 flex flex-wrap space-x-2">
              <div
                v-if="session.participants && session.participants.length"
                class="flex h-24 w-24 flex-col space-y-2.5 rounded-md bg-accent-100 text-center text-core-700 shadow">
                <div
                  class="flex h-1/2 w-full items-end justify-center px-2 text-sm leading-none">
                  <span class="leading-none">Effectif inscrit</span>
                </div>

                <div
                  class="flex h-1/2 w-full items-start justify-center text-2xl font-bold text-jinius-dark-green">
                  <span class="leading-none">{{
                    session.participants.length
                  }}</span>
                </div>
              </div>

              <div
                v-if="formateur && moyenne_prerequis"
                class="flex h-24 w-24 flex-col space-y-2.5 rounded-md bg-accent-100 text-center text-core-700 shadow">
                <div
                  class="flex h-1/2 w-full items-end justify-center px-2 text-sm leading-none">
                  <span class="leading-none">Moyenne prérequis</span>
                </div>

                <div
                  class="flex h-1/2 w-full items-start justify-center text-2xl font-bold text-accent-500">
                  <span class="leading-none">{{ moyenne_prerequis }}</span>
                </div>
              </div>
              <div
                v-if="formateur && moyenne_acquis && session.terminee"
                class="flex h-24 w-24 flex-col space-y-2.5 rounded-md bg-accent-100 text-center text-core-700 shadow">
                <div
                  class="flex h-1/2 w-full items-end justify-center px-2 text-sm leading-none">
                  <span class="leading-none"
                    >Moyenne validation des acquis</span
                  >
                </div>

                <div
                  class="flex h-1/2 w-full items-start justify-center text-2xl font-bold text-accent-500">
                  <span class="leading-none">{{ moyenne_acquis }}</span>
                </div>
              </div>
              <div
                v-if="participant && note_prerequis"
                class="mb-3 mr-3 flex h-32 w-32 flex-col items-center justify-center rounded-lg bg-accent-100 p-4 text-sm shadow">
                <div class="flex-grow text-center font-bold">
                  Note test de positionnement
                </div>
                <div class="mt-2 text-4xl font-bold">{{ note_prerequis }}</div>
              </div>
              <div
                v-if="participant && note_validation_acquis"
                class="mb-3 mr-3 flex h-32 w-32 flex-col items-center rounded-lg bg-accent-100 p-4 text-xs shadow">
                <div class="flex-grow justify-center text-center font-bold">
                  Note validation des acquis
                </div>
                <div class="mt-2 text-4xl font-bold">
                  {{ note_validation_acquis }}
                </div>
              </div>
            </div>
            <div
              class="mb-2 flex w-full justify-end"
              v-if="participantsHasCertificats || participantsHasConventions">
              <div
                class="flex cursor-pointer items-center whitespace-nowrap rounded bg-jinius-dark-green px-6 py-2.5 font-bold leading-none text-white shadow hover:bg-opacity-90"
                v-on:click="openDownloadAllModal">
                <font-awesome-icon
                  icon="download"
                  size="sm" />
                <span class="ml-3"> Tout télécharger (.zip)</span>
              </div>
            </div>
            <div
              v-on:click="onOpenSessionDetailModal"
              v-if="formateur && !isParcoursOrCursus"
              class="white-space-normal my-4 flex w-full cursor-pointer items-center justify-center space-x-6 rounded bg-jinius-dark-green px-6 py-2.5 font-bold leading-none text-white shadow hover:bg-opacity-90">
              <font-awesome-icon
                icon="chart-pie"
                size="lg" />
              <span class="text-sm"
                >Synthèse des appréciations des participants sur la
                session</span
              >
            </div>
            <div
              class="flex flex-wrap"
              v-if="
                !participant &&
                session.participants &&
                session.participants.length
              ">
              <ag-grid-wrapper
                v-bind:column_defs="column_defs_participants"
                v-bind:initial_data="
                  participants.map((p) => ({
                    ...p,
                    ...(p.statut && { statut: p.statut.label })
                  }))
                " />
            </div>
          </div>
        </div>
      </div>

      <details-map-modal
        v-if="!loading && session.site && session.site.coordinates"
        v-on:onclose="closeMapModal"
        v-bind:modal_show="map_modal"
        v-bind:coordinates="session.site.coordinates.split(',')"
        v-bind:adresse="concatAdress(session.site)" />
      <convocation-modal
        v-if="convocation_modal && !loading && session.convocation"
        v-bind:modal_show="convocation_modal"
        v-bind:onclose="closeConvocationModal"
        v-bind:ressource_base_url="ressource_base_url" />
      <examen-modal
        v-bind:modal_show="examen_modal"
        v-bind:onclose="closeExamenModal"
        v-bind:session="session"
        v-bind:fetch_session="fetch_session" />
      <satisfaction-modal
        v-if="
          session.satisfaction && session.satisfaction.id && satisfaction_modal
        "
        v-bind:evaluation_show="satisfaction_modal"
        v-bind:satisfaction="session.satisfaction"
        v-bind:session="session"
        v-bind:onclose="closeEvaluationModal"
        v-bind:fetch_data="fetch_session"
        v-bind:entreprise="entreprise"
        v-bind:participant="participant"
        v-bind:formateur="formateur" />
      <validation-acquis-modal
        v-if="
          !loading &&
          session.testValidation &&
          session.testValidation.id &&
          validation_acquis_show
        "
        v-bind:modal_show="validation_acquis_show"
        v-bind:onclose="closeValidationAcquisModal"
        v-bind:session="session"
        v-bind:fetch_session="fetch_session" />
      <appreciation-modal
        v-bind:modal_show="appreciation_modal"
        v-bind:onclose="closeAppreciationModal"
        v-bind:elearning="session.elearning" />

      <contact-modal
        v-bind:modal_show="contact_modal"
        v-on:onclose="closeContactModal"
        v-bind:current_contact="
          session.contact ? session.contact : session.organisateur
        " />
      <connexion-modal
        v-bind:modal_show="connexion_modal"
        v-on:onclose="closeConnexionModal"
        v-bind:session_url="sessionUrl" />
      <elearning-modal
        v-bind:module_infos="session.moduleElearning"
        v-bind:show_modal="showElearningModal"
        v-bind:open_elearning_module="openElearningModuleModal"
        v-bind:onclose="closeElearningModal" />
      <elearning-question-modal
        v-bind:titre="session.titre"
        v-bind:participant_id="session.participantId"
        v-bind:plateforme_duree_acces="session.plateformeDureeAcces"
        v-bind:module_elearning="session.moduleElearning"
        v-bind:questions="
          session.elearningQuestion && session.elearningQuestion.questions
            ? session.elearningQuestion.questions
            : []
        "
        v-bind:autoriser_question="
          session.elearningQuestion &&
          session.elearningQuestion.autoriserQuestion
        "
        v-bind:show_modal="showElearningQuestionModal"
        v-bind:open_elearning_module="openElearningQuestionModal"
        v-bind:onclose="closeElearningQuestionModal"
        v-bind:refresh_session="refresh_session" />
      <session-details-modal
        v-if="sessionDetails"
        v-bind:show_modal="showSessionDetailsModal"
        v-bind:session="sessionDetails"
        v-bind:onclose="closeSessionDetailModal" />
      <session-participant-details-modal
        v-if="sessionParticipantDetails"
        v-bind:show_modal="showSessionParticipantDetailsModal"
        v-bind:participant="sessionParticipantDetails"
        v-bind:session="session"
        v-bind:client_id="client_id"
        v-bind:onclose="closeSessionParticipantDetailModal" />
      <support-modal
        v-bind:modal_show="showSupportModal"
        v-bind:onclose="closeSupportModal"
        v-bind:supports="supportAvecAppreciationRequise"
        v-bind:elearning="session.elearning" />
      <convention-modal
        v-if="sessionParticipant"
        v-bind:show_modal="showConventionModal"
        v-bind:participant="sessionParticipant"
        v-bind:onclose="closeConventionModal"
        v-bind:refresh_session="refresh_session"
        v-bind:convention_base_url="convention_base_url"
        v-bind:download_convention="downloadConvention" />
      <pif-modal
        v-if="sessionParticipant"
        v-bind:show_modal="showPifModal"
        v-bind:participant="sessionParticipant"
        v-bind:onclose="closePifModal"
        v-bind:refresh_session="refresh_session"
        v-bind:pif_base_url="pif_base_url"
        v-bind:download_pif="downloadPIFClient" />
      <download-all-modal
        v-if="participantsHasCertificats || participantsHasConventions"
        v-bind:show_modal="showDownloadAllModal"
        v-bind:onclose="closeDownloadAllModal"
        v-bind:download_all_base_url="download_all_base_url"
        v-bind:session="session" />
    </div>
  </div>
</template>

<script>
  import agGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';
  import displayAccessibiliteIcons from '@/components/utils/display-accessibilite-icons.vue';
  import FileUpload from '@/components/utils/file-upload';
  import messageBox from '@/components/utils/message-box.vue';
  import programmeList from '@/components/utils/programme-list';
  import seanceDescriptionCard from '@/components/utils/seance-description-card.vue';
  import sessionCard from '@/components/utils/session-card.vue';
  import spinner from '@/components/utils/spinner.vue';
  import { API } from '@/http-common';
  import http_functions from '@/mixin/http_functions';
  import ConnexionModal from '@/views/_espace/connexion-modal';
  import contactModal from '@/views/_espace/contact-modal.vue';
  import detailsMapModal from '@/views/_espace/details-map-modal';
  import ConventionModal from '@/views/_espace/_entreprise/_sessions/_modal/convention-modal';
  import DownloadAllModal from '@/views/_espace/_entreprise/_sessions/_modal/download-all-modal';
  import examenModal from '@/views/_espace/_entreprise/_sessions/_modal/examen-modal';
  import PifModal from '@/views/_espace/_entreprise/_sessions/_modal/pif-modal';
  import validationAcquisModal from '@/views/_espace/_entreprise/_sessions/_modal/validation-acquis-modal';
  import sessionDetailsModal from '@/views/_espace/_formateur/_appreciations/_modal/session-details-modal';
  import sessionParticipantDetailsModal from '@/views/_espace/_formateur/_appreciations/_modal/session-participant-modal';
  import convocationModal from '@/views/_espace/_formateur/_sessions/_modal/convocation-modal';
  import satisfactionModal from '@/views/_espace/_formateur/_sessions/_modal/satisfaction-modal';
  import ElearningModal from '@/views/_espace/_participant/elearning-modal';
  import AppreciationModal from '@/views/_espace/_participant/_sessions/appreciation-modal';
  import ElearningQuestionModal from '@/views/_espace/_participant/_sessions/elearning-question-modal';
  import SupportModal from '@/views/_espace/_participant/_sessions/support-modal';
  import { compareDesc, parse } from 'date-fns';

  export default {
    components: {
      seanceDescriptionCard,
      DownloadAllModal,
      ConventionModal,
      ElearningQuestionModal,
      FileUpload,
      ConnexionModal,
      AppreciationModal,
      validationAcquisModal,
      spinner,
      sessionCard,
      contactModal,
      displayAccessibiliteIcons,
      programmeList,
      detailsMapModal,
      agGridWrapper,
      convocationModal,
      satisfactionModal,
      examenModal,
      ElearningModal,
      messageBox,
      sessionDetailsModal,
      sessionParticipantDetailsModal,
      SupportModal,
      PifModal
    },
    mixins: [http_functions],
    props: {
      session: Object,
      espace: String,
      client_id: Number,
      go_to_details: Function,
      download_facture: Function,
      fetch_session: Function,
      refresh_session: Function,
      open_elearning_module: Function,
      convention_base_url: String,
      pif_base_url: String,
      download_all_base_url: String,
      ressource_base_url: String,
      back_base_url: String
    },
    data: () => ({
      loading: false,
      map_modal: false,
      convocation_modal: false,
      satisfaction_modal: false,
      examen_modal: false,
      validation_acquis_show: false,
      appreciation_modal: false,
      contact_modal: false,
      connexion_modal: false,
      showElearningModal: false,
      showElearningQuestionModal: false,
      showSessionDetailsModal: false,
      sessionDetails: null,
      showSessionParticipantDetailsModal: false,
      sessionParticipantDetails: null,
      showSupportModal: false,
      showConventionModal: false,
      showPifModal: false,
      showDownloadAllModal: false,
      sessionParticipant: null
    }),
    computed: {
      isNewRuleStarted() {
        const breakpoint_date = new Date(2023, 6, 7);

        const is_match_today = compareDesc(breakpoint_date, new Date()) >= 0;

        const end_date = parse(this.session.dateFin, 'dd/MM/yyyy', new Date());

        const is_match_end_date = compareDesc(breakpoint_date, end_date) >= 0;

        return is_match_end_date && is_match_today;
      },
      column_defs_participants() {
        const columns = [
          {
            headerName: 'Nom',
            field: 'nom',
            filter: true,
            floatingFilter: true
          },
          {
            headerName: 'Prénom',
            field: 'prenom',
            filter: true,
            floatingFilter: true
          }
        ];

        if (this.formateur) {
          columns.push(
            {
              headerName: 'Poste',
              field: 'poste',
              filter: true,
              floatingFilter: true
            },
            {
              headerName: 'Note prérequis',
              field: 'notePositionnement',
              filter: true,
              floatingFilter: true,
              cellRenderer: 'textCenterCellRenderer'
            }
          );
          if (this.session.terminee) {
            columns.push({
              headerName: 'Note validation des acquis',
              field: 'noteValidationAcquis',
              filter: true,
              floatingFilter: true,
              cellRenderer: 'textCenterCellRenderer'
            });
          }
        }
        if (this.entreprise) {
          columns.push(
            {
              headerName: 'Client',
              field: 'client',
              cellRenderer: 'textCellRenderer',
              filter: true,
              floatingFilter: true
            },
            {
              headerName: 'Statut',
              field: 'statut',
              cellRenderer: 'textCellRenderer',
              filter: true,
              floatingFilter: true
            }
          );
          if (this.participantHasCertificat) {
            columns.push({
              headerName: 'Certificat de réalisation',
              field: 'certificat',
              cellRenderer: 'buttonCellRenderer',
              cellRendererParams: {
                label: 'Télécharger',
                labelDisabled: 'Non disponible',
                onclick: (e, params) => this.downloadCertificat(e, params)
              }
            });
          }
          if (this.participantHasConvention) {
            columns.push({
              headerName: 'Convention',
              field: 'convention',
              cellRendererParams: {
                label: 'DETAILS',
                labelDisabled: 'Non disponible',
                onclick: (e, params) =>
                  this.onOpenConventionModal(e, params.data)
              }
            });
          }
          if (this.participantHasPif) {
            columns.push({
              headerName: 'PIF',
              field: 'pif',
              cellRendererParams: {
                label: 'DETAILS',
                labelDisabled: 'Non disponible',
                onclick: (e, params) => this.onOpenPifModal(e, params.data)
              }
            });
          }
          if (!this.isParcoursOrCursus) {
            columns.push({
              headerName: 'Satisfaction',
              field: 'details',
              cellRendererParams: {
                alwaysDisplay: true,
                label: 'DETAILS',
                onclick: (e, params) =>
                  this.onOpenSessionParticipantDetailModal(e, params.data)
              }
            });
          }
          if (this.session.elearning) {
            columns.push({
              headerName: 'Statut',
              field: 'elearningStatut'
            });
            if (!this.session.produit) {
              columns.push(
                {
                  headerName: "Date début d'accès",
                  field: 'dateDebutAcces'
                },
                {
                  headerName: "Date fin d'accès",
                  field: 'dateFinAcces'
                }
              );
            }
          }

          if (!this.session.elearning) {
            columns.push(
              {
                headerName: 'Test de positionnement',
                field: 'testPositionnement',
                cellRenderer: 'textCellRenderer',
                filter: true,
                floatingFilter: true
              },
              {
                headerName: 'Test de validation des acquis',
                field: 'testValidationAcquis',
                cellRenderer: 'textCellRenderer',
                filter: true,
                floatingFilter: true
              }
            );
          }
        }

        return columns;
      },
      isParcoursOrCursus() {
        return !!(this.session.produits && this.session.produits.length);
      },
      entreprise() {
        return this.espace === 'entreprise';
      },
      formateur() {
        return this.espace === 'formateur';
      },
      participant() {
        return this.espace === 'participant' || this.espace === 'stagiaire';
      },
      note_prerequis() {
        if (
          this.session &&
          this.session.testPositionnement &&
          this.session.testPositionnement.repondu &&
          (this.session.testPositionnement.nbreBonnesResponses ||
            this.session.testPositionnement.nbreBonnesResponses === 0) &&
          this.session.testPositionnement.nbreQuestions
        ) {
          const { nbreBonnesResponses, nbreQuestions } =
            this.session.testPositionnement;

          return `${nbreBonnesResponses}/${nbreQuestions}`;
        }

        return false;
      },
      note_validation_acquis() {
        if (
          this.session &&
          this.session.testValidation &&
          this.session.testValidation.repondu &&
          (this.session.testValidation.nbreBonnesResponses ||
            this.session.testValidation.nbreBonnesResponses === 0) &&
          this.session.testValidation.nbreQuestions
        ) {
          const { nbreBonnesResponses, nbreQuestions } =
            this.session.testValidation;

          return `${nbreBonnesResponses}/${nbreQuestions}`;
        }

        return false;
      },
      moyenne_prerequis() {
        if (this.session && this.session.participants) {
          return this.getMoyenne('testPositionnement');
        }

        return false;
      },
      moyenne_acquis() {
        if (this.session && this.session.participants) {
          return this.getMoyenne('testValidationAcquis');
        }

        return false;
      },
      participants() {
        return (
          this.session &&
          this.session.participants.map((participant) => {
            const { testPositionnement, testValidationAcquis } = participant;

            return {
              ...participant,
              notePositionnement: this.getNote(testPositionnement),
              noteValidationAcquis: this.getNote(testValidationAcquis)
            };
          })
        );
      },
      presences() {
        if (
          this.session &&
          this.session.presences &&
          this.session.presences.length > 1
        ) {
          const presences = [...this.session.presences];

          presences.sort((a, b) => a.ordre - b.ordre);

          return presences;
        }

        return this.session.presences;
      },
      sessionUrl() {
        if (this.session) {
          const { sessionUrlFormateur, sessionUrlParticipant } = this.session;

          if (sessionUrlFormateur && sessionUrlFormateur.length) {
            return sessionUrlFormateur;
          } else if (sessionUrlParticipant && sessionUrlParticipant.length) {
            return sessionUrlParticipant;
          } else {
            return [];
          }
        }

        return [];
      },
      convention_signee() {
        const { convention } = this.session;

        return (
          convention &&
          convention.id &&
          convention.valideeFileName &&
          convention.signee
        );
      },
      convention_disponible() {
        const { convention } = this.session;

        return convention && convention.id;
      },
      pif_uploaded() {
        const { pif } = this.session;

        return pif && pif.id && pif.signeeFileName;
      },
      pif_signee() {
        const { pif } = this.session;

        return pif && pif.id && pif.valideeFileName && pif.signee;
      },
      pif_disponible() {
        const { pif } = this.session;

        return pif && pif.id;
      },
      supportAvecAppreciationRequise() {
        const { support } = this.session;

        return (
          support &&
          support.supports &&
          support.supports.filter((s) => s.participantAppreciationRequise)
        );
      },
      participantHasConvention() {
        const { participants } = this.session;

        return participants.some((p) => p.convention && p.convention.id);
      },
      participantHasPif() {
        const { participants } = this.session;

        return participants.some((p) => p.pif && p.pif.id);
      },
      participantHasCertificat() {
        const { participants } = this.session;

        return participants.some((p) => p.certificat && p.certificat.id);
      },
      participantsHasCertificats() {
        const { participants } = this.session;

        return (
          participants &&
          participants.length &&
          participants.filter((p) => p.certificat && p.certificat.id).length > 1
        );
      },
      participantsHasConventions() {
        const { participants } = this.session;

        return (
          participants &&
          participants.length &&
          participants.filter(
            (p) =>
              p.convention &&
              (p.convention.signeeFileName || p.convention.valideeFileName)
          ).length > 1
        );
      },
      nbre_tentatives_restantes() {
        const { testValidation } = this.session;

        return (
          testValidation.nbreTentatives -
          (testValidation.nbreTentativeSupplementaire
            ? testValidation.nbreTentativeSupplementaire
            : 0) -
          1
        );
      }
    },
    methods: {
      getTypeList(classe_virtuelle, type, ficheType) {
        const mergedType = {
          id: type.id,
          label: ficheType ? ficheType.label : type.label
        };

        return classe_virtuelle && [1, 4].includes(type.id)
          ? [
              mergedType,
              {
                label: 'Classe virtuelle',
                id: 5
              }
            ]
          : [mergedType];
      },
      async openElearningModuleModal() {
        await this.open_elearning_module();
        await this.closeElearningModal();
        await this.fetch_session();
      },
      openElearningModule(module) {
        if (module.showRetractation) {
          this.openElearningModal();
        } else {
          this.open_elearning_module();
        }
      },
      openElearningModal() {
        this.showElearningModal = true;
      },
      closeElearningModal() {
        this.showElearningModal = false;
      },
      openElearningQuestionModal() {
        this.showElearningQuestionModal = true;
      },
      closeElearningQuestionModal() {
        this.showElearningQuestionModal = false;
      },
      async downloadCertificat(e, params) {
        const { nom, id } = params.data.certificat;
        const clientId = this.client_id;
        const downloadUrl = `/client/${clientId}/certificat/${id}/file`;

        await this.downloadFile(downloadUrl, nom);
      },
      async downloadCertificatParticulier(certificat) {
        const { nom, id } = certificat;
        const downloadUrl = `/participant/certificat/${id}/file`;

        await this.downloadFile(downloadUrl, nom);
      },
      async downloadPIFClient(id, type, nom) {
        await this.downloadFile(`${this.pif_base_url}/${id}/file/${type}`, nom);
      },
      async downloadConvention(id, type, nom) {
        await this.downloadFile(
          `${this.convention_base_url}/${id}/file/${type}`,
          nom
        );
      },
      async downloadPIFParticipant(type, nom) {
        const pif = this.session.pif;

        await this.downloadFile(
          `participant/${this.session.participantId}/pif/${pif.id}/file/${type}`,
          nom
        );
      },
      async downloadFile(url, nom) {
        const downloadUrl = `${import.meta.env.VITE_API_SAFIR}/${url}`;
        const fileLink = document.createElement('a');

        fileLink.href = downloadUrl;
        fileLink.setAttribute('download', nom);
        document.body.appendChild(fileLink);
        const simulateClick = function (elem) {
          // bubbles: false to avoid closing modal on download
          const evt = new MouseEvent('click', { bubbles: false });

          elem.dispatchEvent(evt);
        };

        simulateClick(fileLink);
        document.body.removeChild(fileLink);
      },
      getNote(test) {
        if (test) {
          const { nbreQuestions, nbreBonnesReponses } = test;

          return nbreBonnesReponses || nbreBonnesReponses === 0
            ? `${nbreBonnesReponses}/${nbreQuestions}`
            : 'Aucune notation';
        }

        return null;
      },
      getMoyenne(test) {
        if (test) {
          const notes = this.session.participants
            .map(
              (participant) =>
                participant[test] && participant[test].nbreBonnesReponses
            )
            .filter((nb) => nb === 0 || nb);

          if (notes && notes.length) {
            const sum = notes.reduce((a, b) => a + b, 0);
            const moyenne = Math.round((sum / notes.length) * 10) / 10;
            const nbreQuestions =
              this.session.participants[0][test].nbreQuestions;

            return `${moyenne}/${nbreQuestions}`;
          }
        }

        return null;
      },
      onOpenModalContact(id) {
        this.contact_modal = true;
      },
      onOpenSessionDetailModal() {
        this.sessionDetails = this.session;
        this.showSessionDetailsModal = true;
      },
      closeSessionDetailModal() {
        this.sessionDetails = null;
        this.showSessionDetailsModal = false;
      },
      onOpenSessionParticipantDetailModal(e, participant) {
        this.sessionParticipantDetails = participant;
        this.showSessionParticipantDetailsModal = true;
      },
      closeSessionParticipantDetailModal() {
        this.sessionParticipantDetails = null;
        this.showSessionParticipantDetailsModal = false;
      },
      closeContactModal() {
        this.contact_modal = false;
      },
      openAppreciationModal() {
        this.appreciation_modal = true;
      },
      closeAppreciationModal() {
        this.appreciation_modal = false;
      },
      openConnexionModal() {
        this.connexion_modal = true;
      },
      closeConnexionModal() {
        this.connexion_modal = false;
      },
      openMapModal(e) {
        e.preventDefault();
        this.map_modal = true;
      },
      closeMapModal() {
        this.map_modal = false;
      },
      concatAdress(site) {
        return `${site.denomination} - ${site.numeroVoie}, ${site.codePostal} ${site.ville}`;
      },
      openConvocationModal() {
        this.convocation_modal = true;
      },
      closeConvocationModal() {
        this.convocation_modal = false;
      },
      openEvaluationModal(session) {
        if (!session.satisfaction.dateReponse && session.satisfaction.id) {
          this.satisfaction_modal = true;
        }
      },
      closeEvaluationModal() {
        this.satisfaction_modal = false;
      },
      openExamenModal(session) {
        if (session.examen.id && !session.examen.termine) {
          this.examen_modal = true;
        }
      },
      closeExamenModal() {
        this.examen_modal = false;
      },
      goToUrl(url) {
        window.open(this.formatUrlProtocol(url), '_blank');
      },
      async downloadAttestation(url, nom) {
        const downloadUrl = `${import.meta.env.VITE_API_SAFIR}/${url}`;
        const fileLink = document.createElement('a');

        fileLink.href = downloadUrl;
        fileLink.setAttribute('download', nom);
        document.body.appendChild(fileLink);
        const simulateClick = function (elem) {
          // bubbles: false to avoid closing modal on download
          const evt = new MouseEvent('click', { bubbles: false });

          elem.dispatchEvent(evt);
        };

        simulateClick(fileLink);
        document.body.removeChild(fileLink);
      },
      closeValidationAcquisModal() {
        this.validation_acquis_show = false;
      },
      isTestValidationOuvrable(session) {
        return (
          session.testValidation.id &&
          !session.testValidation.reussi &&
          (!session.testValidation.repondu ||
            (session.testValidation.repassable &&
              (!session.testValidation.nbreTentativeSupplementaire ||
                session.testValidation.nbreTentativeSupplementaire + 1 <
                  session.testValidation.nbreTentatives)))
        );
      },
      openValidationAcquisModal(session) {
        if (this.isTestValidationOuvrable(session)) {
          this.validation_acquis_show = true;
        }
      },

      async downloadFeuillePresence(presence) {
        if (this.formateur && presence) {
          const { nom, titre, seanceId } = presence;
          const { sessionId, produitId, reference } = this.session;
          const isProduit = reference && reference.indexOf('-') > 0;
          const fileName = nom ? nom : titre;
          const response = await API.get(
            `formateur/session/${sessionId}/presence/${seanceId}/telechargement${isProduit ? `?parcoursProduitId=${produitId}` : ''}`,
            {
              responseType: 'blob',
              ...this.$header_skip_redirection
            }
          );
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute(
            'download',
            `presence-${fileName
              .toLowerCase()
              .replaceAll(' ', '')
              .substr(0, 100)}.pdf`
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      },
      async downloadSupports() {
        const isElearning = this.session.type && this.session.type.id === 2;
        const sessionCondition =
          !isElearning &&
          this.supportAvecAppreciationRequise.length &&
          (!this.session.satisfaction ||
            !this.session.satisfaction.dateReponse);
        const elearningCondition =
          isElearning &&
          this.supportAvecAppreciationRequise.length &&
          !this.session.moduleElearning &&
          !this.session.moduleElearning.termine;

        if (sessionCondition || elearningCondition) {
          this.openSupportModal();
        } else {
          const downloadUrl = `${import.meta.env.VITE_API_SAFIR}${this.ressource_base_url}/supports`;
          const fileLink = document.createElement('a');

          fileLink.href = downloadUrl;
          fileLink.setAttribute('download', 'supports');
          document.body.appendChild(fileLink);
          const simulateClick = function (elem) {
            // bubbles: false to avoid closing modal on download
            const evt = new MouseEvent('click', { bubbles: false });

            elem.dispatchEvent(evt);
          };

          simulateClick(fileLink);
          document.body.removeChild(fileLink);
        }
      },
      async downloadDocuments() {
        const downloadUrl = `${import.meta.env.VITE_API_SAFIR}${this.ressource_base_url}/documents`;
        const fileLink = document.createElement('a');

        fileLink.href = downloadUrl;
        fileLink.setAttribute('download', 'supports');
        document.body.appendChild(fileLink);
        const simulateClick = function (elem) {
          // bubbles: false to avoid closing modal on download
          const evt = new MouseEvent('click', { bubbles: false });

          elem.dispatchEvent(evt);
        };

        simulateClick(fileLink);
        document.body.removeChild(fileLink);
      },
      openSupportModal() {
        this.showSupportModal = true;
      },
      closeSupportModal() {
        this.showSupportModal = false;
      },
      onOpenConventionModal(e, participant) {
        const { id } = participant;

        this.sessionParticipant = this.session.participants.find(
          (p) => p.id === id
        );
        this.showConventionModal = true;
      },
      onOpenPifModal(e, participant) {
        const { id } = participant;

        this.sessionParticipant = this.session.participants.find(
          (p) => p.id === id
        );
        this.showPifModal = true;
      },
      closePifModal() {
        this.sessionParticipant = null;
        this.showPifModal = false;
      },
      closeConventionModal() {
        this.sessionParticipant = null;
        this.showConventionModal = false;
      },
      openDownloadAllModal() {
        this.showDownloadAllModal = true;
      },
      closeDownloadAllModal() {
        this.showDownloadAllModal = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .details__container {
    @apply w-full px-4 lg:pl-3 lg:pr-1;
    :deep(.aggrid__title) {
      @apply mb-3 ml-8 text-xl font-bold leading-none text-core-700;
    }

    .ressources__download__link__container {
      @apply mb-3 flex cursor-pointer items-center;

      .ressources__download__link__button {
        @apply ml-3 flex items-center;

        .ressources__download__link__button__content {
          @apply flex h-8 w-full items-center justify-center rounded bg-accent-200 p-4 text-center text-sm font-bold uppercase text-core-500 shadow-md disabled:cursor-not-allowed disabled:opacity-75;
        }
      }

      .ressources__download__link__label {
        @apply ml-8 text-xl font-bold leading-none text-core-700;
      }

      &:hover {
        .ressources__download__link__button {
          .ressources__download__link__button__content {
            @apply bg-accent-300 text-core-600;
          }
        }

        .ressources__download__link__label {
          @apply text-primary-800;
        }
      }
    }
  }

  :deep(.ressources__standalone_button__content) {
    @apply my-4 flex h-8 w-full items-center justify-center rounded bg-accent-200 p-4 text-center text-sm font-bold uppercase text-core-500 shadow-md disabled:cursor-not-allowed disabled:opacity-75;
    &:hover {
      @apply bg-accent-300 text-core-600;
    }
  }

  .status-label-wrapper {
    @apply mt-2 flex flex-wrap space-x-3;

    .status-label {
      @apply flex h-2 w-2 cursor-pointer rounded-full shadow;
    }
  }
</style>
