import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M9.334 15V7.789h1.318V15zm.665-8.324a.8.8 0 0 1-.59-.244.82.82 0 0 1-.243-.592q0-.346.243-.591A.8.8 0 0 1 9.999 5q.345 0 .587.249.247.245.247.591a.8.8 0 0 1-.247.592.8.8 0 0 1-.587.244"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "10",
      cy: "10",
      r: "7.5",
      stroke: "currentColor"
    }, null, -1)
  ])))
}
export default { render: render }