import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      d: "M3.334 5.263h2.574M3.334 8.632h5.149M3.334 12h9.011"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M11.667 4v4.667m0 0L13 7.333m-1.333 1.334-1.333-1.334"
    }, null, -1)
  ])))
}
export default { render: render }