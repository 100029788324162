import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M9.986 10C8.058 10 6.13 8.369 5.985 5.107c-.01-.221-.192-.395-.404-.456C4.4 4.311 4.159 2.5 5.976 2.5h8.02c1.862 0 1.995 1.902.4 2.174a.424.424 0 0 0-.37.43c.021.76-.162 2.449-2.193 3.8M10.014 10c1.937 0 3.874 1.647 4.004 4.94.007.196.19.348.401.409 1.181.339 1.423 2.151-.395 2.151h-8.02c-1.862 0-1.995-1.901-.4-2.174.217-.037.38-.188.372-.383-.029-.705.105-2.323 1.939-3.67"
    }, null, -1)
  ])))
}
export default { render: render }