import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#869DF0",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g stroke=\"currentColor\" stroke-linecap=\"round\" clip-path=\"url(#a)\"><path stroke-linejoin=\"round\" stroke-width=\".4\" d=\"M12.2 12.748v1.314a.4.4 0 0 1-.4.4H3.6a.4.4 0 0 1-.4-.4v-11.2c0-.221.18-.4.4-.4h6.1\"></path><path stroke-width=\".4\" d=\"M4.867 4.462h4m-4 1.5h4m-4 1.5h4\"></path><path d=\"M4.867 11.502c1.166-.984 2.415-1.64 3.11-1.64 1.167 0-.388 2.296.39 1.968.777-.328 1.166-1.312 1.944-1.312s-.84 1.312.389 1.312c.778 0 1.167-.656 1.167-.656\"></path><path stroke-linejoin=\"round\" d=\"M10.907 3.347v-1.45c0-.22.179-.4.4-.4H12.7c.221 0 .4.18.4.4v1.45m-2.193 0v4.666a1 1 0 0 0 .153.533l.714 1.133a.2.2 0 0 0 .338.002l.764-1.192a1 1 0 0 0 .158-.525l.066-4.617m-2.193 0H13.1\"></path></g><defs><clipPath id=\"a\"><rect fill=\"#fff\"></rect></clipPath></defs>", 2)
  ])))
}
export default { render: render }