import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" clip-path=\"url(#a)\"><path stroke-width=\".8\" d=\"m11.31 12.933-.76-.335a.8.8 0 0 1-.014-1.458l2.481-1.153a3 3 0 0 1 2.528 0l2.482 1.153a.8.8 0 0 1-.014 1.458l-.76.335m-5.943 0 2.291.868a2 2 0 0 0 1.367.019l1.096-.382m-4.754-.505v2.369c0 .986 2.41 1.581 3.998 1.162\"></path><path stroke-width=\".8\" d=\"m14.281 11.574 2.274 1.057a1 1 0 0 1 .579.906v2.013m0 0 .713.994m-.713-.994-.713.994\"></path><path d=\"m3.114 11.382-.82.85a2 2 0 0 0-.421 2.123l.991 2.512a1 1 0 0 0 .93.633h7.034m.286-10.737c0 2.355-1.79 4.263-4 4.263s-4-1.908-4-4.263 1.791-4.263 4-4.263 4 1.909 4 4.263\"></path></g><defs><clipPath id=\"a\"><path fill=\"currentColor\" d=\"M0 0h20v20H0z\"></path></clipPath></defs>", 2)
  ])))
}
export default { render: render }