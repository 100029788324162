<template>
  <div
    class="seance-description-card group"
    v-bind:class="[{ disabled: seance.disabled }]">
    <div class="card__container">
      <div class="container__content">
        <div class="content__type">
          <div
            v-bind:class="`type__label ${seance.disabled ? 'bg-core-500' : type_bg_color(seance.type.id)}`">
            <span>{{ seance.type.label }}</span>
          </div>
        </div>

        <div class="content__description">
          <div class="description__left">
            <div class="content__title">
              <span class="content__title__text">
                <span data_id="titre">
                  {{ seance.titre }}
                </span>
              </span>
            </div>

            <div
              v-if="seance.dureeMinutes"
              class="flex items-center space-x-1.5">
              <font-awesome-icon v-bind:icon="['far', 'clock']" />
              <span>
                {{ formatMinutesToHours(seance.dureeMinutes) }}
              </span>
            </div>

            <div
              v-if="
                seance.type.id === 2 &&
                (seance.dateDebutAcces || seance.dateFinAcces)
              ">
              <span
                class="inline-flex w-4 items-center justify-center align-middle">
                <font-awesome-icon
                  v-bind:icon="['far', 'calendar-alt']"
                  size="1x" />
              </span>

              <span class="mx-2 align-middle">
                <em class="text-accent-500">Durée d'accessibilité&nbsp;: </em>
                <span data_id="duree">
                  {{ formatDate(seance.dateDebutAcces, seance.dateFinAcces) }}
                </span>
              </span>
            </div>
            <!-- <div v-if="seance.type.id === 2 && seance.prerequisPourcentage">
              <span class="w-4 inline-flex align-middle items-center justify-center">
                <font-awesome-icon icon="info"
                                   size="1x"/>
              </span>

              <span class="mx-2 align-middle">
                <em class="text-accent-500">Avancement min. requis&nbsp;: </em>
                <span data_id="organisateur">
                  {{seance.prerequisPourcentage}}&nbsp;%
                </span>
              </span>
            </div> -->
          </div>

          <div class="description__right">
            <div
              v-if="seance.avancement || seance.avancement === 0"
              class="flex justify-end">
              <div class="progress-wrapper">
                <div
                  class="mb-1 flex w-full items-center justify-between text-base">
                  <em class="text-accent-500">Avancement : </em>
                  <span data_id="avancement"> {{ seance.avancement }}% </span>
                </div>
                <div class="progress-container">
                  <div
                    class="progress-bar"
                    v-bind:style="`width: ${seance.avancement}%;`"></div>
                </div>
              </div>
            </div>
            <div class="mt-2 flex w-full justify-start">
              <div
                v-if="seance.url || seance.stagiairePifRequis"
                v-on:click.stop="openLienElearning(seance)"
                v-tooltip="
                  seance.stagiairePifRequis
                    ? {
                        content: `Merci de télécharger votre PIF dans l'espace «Mes informations» pour accéder à la plateforme de e-learning`,
                        placement: 'top'
                      }
                    : {}
                "
                v-bind:class="`${seance.stagiairePifRequis ? 'bg-core-500 opacity-75' : 'bg-orange-500 hover:bg-orange-600'} my-2 flex cursor-pointer items-center rounded px-4 py-2 text-center text-sm font-bold uppercase leading-none text-white shadow-sm`">
                <font-awesome-icon icon="external-link-alt" />
                <span class="ml-2 align-middle">Accéder à la formation</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { convertStringToDate } from '@/assets/data/ag_grid_fields_map';
  import { map_type_colors } from '@/assets/data/data_maps.js';
  import dates_helpers from '@/mixin/dates_helpers';
  import helpers_filters from '@/mixin/helpers_filters';
  import number_utils from '@/mixin/number_utils';

  export default {
    mixins: [helpers_filters, dates_helpers, number_utils],
    props: {
      seance: {
        type: Object,
        default: null
      },
      optional: { type: Boolean }
    },
    data() {
      return { optional_selection: null };
    },
    methods: {
      type_anchor_color(id) {
        return `border-${map_type_colors[id]}`;
      },
      type_bg_color(id) {
        return `bg-${map_type_colors[id]}`;
      },
      openLienElearning(seance) {
        if (seance.url) {
          window.open(seance.url);
        }
      },
      goToFiche(reference, url) {
        if (reference && url) {
          window.open(`${url}/fiche/${reference}`);
        }
      },
      formatDate(dateDebut, dateFin) {
        let dateString = '';

        if (dateDebut && dateFin) {
          dateString = `du ${dateDebut} au ${dateFin}`;
        }
        if (dateDebut) {
          dateString = `à partir du ${dateDebut}`;
        }
        if (dateFin) {
          dateString = `jusqu'au ${dateFin}`;
        }

        return dateString;
      },
      displayDates(dates) {
        const sortedDates = dates
          .slice()
          .sort(
            (a, b) =>
              convertStringToDate(a.date).getTime() -
              convertStringToDate(b.date).getTime()
          );

        return sortedDates.map(
          (date) =>
            `${date.date}${date.matin && !date.aprem ? ' (matin)' : ''}${!date.matin && date.aprem ? ' (après-midi)' : ''}`
        );
      }
    }
  };
</script>

<style lang="scss" scoped>
  @use '@/assets/css/utils/mixins' as mixin;

  .seance-description-card {
    &.disabled {
      @apply text-core-500 opacity-75;
    }
    @apply mb-4 flex overflow-hidden rounded-lg bg-white shadow transition;

    .card__container {
      @apply relative flex w-full items-center;

      .card__container--anchor {
        @apply absolute left-0 top-0 z-10 h-10 w-12 border-l-8 border-t-8;
      }

      .container__content {
        @apply relative h-full w-full py-5 pl-4 pr-3 tracking-tight shadow;

        .content__type {
          @apply absolute right-0 top-0 mr-2 mt-2 flex space-x-2;
          .type__label {
            @apply rounded-full px-3 py-0.5 text-xs font-bold lowercase text-core-100 shadow first-letter:capitalize;
          }
        }

        .content__title {
          @apply mb-4 text-xl font-bold leading-tight;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }

        .content__description {
          @apply mt-6 flex flex-wrap items-end justify-between text-sm md:mt-4 md:flex-nowrap;

          .description__left {
            @apply w-full flex-grow self-start pl-5 pr-3;
          }

          .description__right {
            @apply mt-6 flex w-full flex-wrap px-4 md:w-120 md:justify-end;
            > div {
              @apply flex w-full justify-end;
            }

            .progress-wrapper {
              @apply mt-2 flex w-64 flex-col;

              .progress-container {
                @apply h-4 w-full rounded bg-core-300;

                .progress-bar {
                  @apply h-full rounded bg-blue-600;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
