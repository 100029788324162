import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      width: "14",
      height: "10.25",
      x: "3",
      y: "3.5",
      stroke: "currentColor",
      rx: "1.5"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      d: "M6.25 16.75h1.875m5.625 0h-1.875m0 0v-2.5m0 2.5h-3.75m0 0v-2.5"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-width": ".4",
      d: "m5 7.375 1.25-1.25M5 9.25 8.75 5.5"
    }, null, -1)
  ])))
}
export default { render: render }