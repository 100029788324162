import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M5.556 2v2.222M10.889 2v2.222\"></path><path stroke=\"currentColor\" d=\"M14.444 9.555V4.89a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v8.444a2 2 0 0 0 2 2h6\"></path><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M6.444 12.222h3.111M4.667 12.222h-.445M6.444 9.556h5.778M4.667 9.556h-.445M6.444 6.889h5.778M4.667 6.889h-.445\"></path><circle cx=\"13.556\" cy=\"13.556\" r=\"3.944\" stroke=\"currentColor\"></circle><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M13.556 10.82v2.321a1 1 0 0 0 .293.707l1.074 1.075\"></path>", 5)
  ])))
}
export default { render: render }