import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path stroke=\"currentColor\" d=\"M12.617 8.707V3.5a1 1 0 0 0-1-1H3.5a1 1 0 0 0-1 1v12.222a1 1 0 0 0 1 1h6.296\"></path><rect width=\"2.267\" height=\"2.267\" x=\"4.459\" y=\"4.477\" stroke=\"currentColor\" stroke-width=\".4\" rx=\".4\"></rect><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\".4\" d=\"M7.815 5.03h1.6m-1.6 1.196h3.2\"></path><rect width=\"2.267\" height=\"2.267\" x=\"4.459\" y=\"8.344\" stroke=\"currentColor\" stroke-width=\".4\" rx=\".4\"></rect><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\".4\" d=\"M7.815 8.896h2.667m-2.667 1.196h1.667\"></path><rect width=\"2.267\" height=\"2.267\" x=\"4.459\" y=\"12.211\" stroke=\"currentColor\" stroke-width=\".4\" rx=\".4\"></rect><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\".4\" d=\"M7.815 12.763H8.5m-.685 1.196H8.5\"></path><path stroke=\"currentColor\" d=\"M18 13.166C18 15.841 15.856 18 13.222 18s-4.779-2.16-4.779-4.834 2.145-4.833 4.779-4.833S18 10.493 18 13.166Z\"></path><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"m11.023 13.166 1.254 1.69a.4.4 0 0 0 .648-.007l2.32-3.283\"></path>", 9)
  ])))
}
export default { render: render }