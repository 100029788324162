import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "m5.88 11.382-.82.85a2 2 0 0 0-.42 2.123l.991 2.512a1 1 0 0 0 .93.633h6.464a1 1 0 0 0 .86-.49l1.496-2.525a2 2 0 0 0-.298-2.424l-2.316-2.346m1.114-2.952c0 2.355-1.791 4.263-4 4.263s-4-1.908-4-4.263S7.67 2.5 9.88 2.5s4 1.909 4 4.263M17.234 8.81l1.182 1.198a2 2 0 0 1 .298 2.424l-.638 1.078a1 1 0 0 1-.86.49H17m-1.69-4.316c.337 0 .66-.067.957-.189 1-.41 1.71-1.443 1.71-2.653s-.71-2.243-1.71-2.653A2.5 2.5 0 0 0 15.31 4M2.766 8.81l-1.182 1.198a2 2 0 0 0-.298 2.424l.638 1.078a1 1 0 0 0 .86.49H3m1.69-4.316c-.337 0-.66-.067-.957-.189-1-.41-1.71-1.443-1.71-2.653s.71-2.243 1.71-2.653C4.03 4.067 4.353 4 4.69 4"
    }, null, -1)
  ])))
}
export default { render: render }