import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#869DF0",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m4.683 7.183 3.034 3.034a.4.4 0 0 0 .566 0l3.034-3.034a.4.4 0 0 0-.283-.683H4.966a.4.4 0 0 0-.283.683"
    }, null, -1)
  ])))
}
export default { render: render }