import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      width: "8.625",
      height: "8.429",
      x: "7.875",
      y: "7.071",
      stroke: "currentColor",
      rx: "1.5"
    }, null, -1),
    _createElementVNode("rect", {
      width: "8.625",
      height: "8.429",
      x: "3.5",
      y: "4.5",
      stroke: "currentColor",
      rx: "1.5"
    }, null, -1)
  ])))
}
export default { render: render }