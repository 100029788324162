import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M13.429 14.542H18c0-1.833-.352-4.32-1.714-6.237M6.57 14.542H2C2 11.114 3.23 5.4 10 5.4c.616 0 1.187.048 1.714.136"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M11.39 15a1.714 1.714 0 0 1-3.081-1.502c.287-.59 3.66-4.889 5.71-7.48.282-.358.839-.041.679.385-1.143 3.04-3.022 8.012-3.308 8.598"
    }, null, -1)
  ])))
}
export default { render: render }