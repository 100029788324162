<template>
  <div>
    <section class="section__primary">
      <h1 class="section__title">Animation et enseignement</h1>

      <div class="section__body">
        <div class="mb-6 flex w-full">
          <ag-grid-wrapper
            editable="true"
            no_filters="true"
            title="Parcours - Résumé"
            description="Indiquez votre parcours en tant qu'animateur et enseignant"
            v-bind:column_defs="[
              {
                headerName: 'De',
                field: 'debut',
                type: 'annees62',
                cellRenderer: 'textCellRendererEditable',
                width: 100
              },
              {
                headerName: 'A',
                field: 'fin',
                type: 'annees62',
                cellRenderer: 'textCellRendererEditable',
                width: 100
              },
              {
                headerName:
                  'Organisme de formation / Etablissement d\'enseignement',
                field: 'of',
                type: 'textWithMax',
                cellEditorParams: { maxLength: 150 },
                cellRenderer: 'textCellRendererEditable',
                flex: 1,
                minWidth: 300
              },
              {
                headerName: 'Titre de la prestation',
                field: 'prestation',
                type: 'textWithMax',
                cellEditorParams: { maxLength: 150 },
                cellRenderer: 'textCellRendererEditable',
                width: 200
              }
            ]"
            v-bind:initial_data="cv_form.parcoursAnimation"
            v-on:cell-value-changed="
              set_attribute($event, 'parcoursAnimation')
            " />
        </div>

        <div class="mb-6 flex w-full">
          <ag-grid-wrapper
            editable="true"
            no_filters="true"
            title="Formations suivies"
            description="Indiquez dans le tableau suivant si vous avez suivi récemment une ou plusieurs formation(s) significative(s) dans le domaine de l'animation (techniques d'animation, formation de formateurs sur les contenus, coaching...)"
            v-bind:column_defs="[
              {
                headerName: 'Année',
                field: 'annee',
                type: 'annees7',
                cellRenderer: 'textCellRendererEditable',
                width: 100
              },
              {
                headerName: 'Titre',
                field: 'objet',
                type: 'textWithMax',
                cellEditorParams: { maxLength: 150 },
                cellRenderer: 'textCellRendererEditable',
                flex: 1,
                minWidth: 200
              },
              {
                headerName: 'Durée',
                field: 'dureeJour',
                type: 'number',
                cellEditorParams: { max: 999.9, maxLength: 5 },
                cellRenderer: 'textCellRendererEditable',
                width: 100
              },
              {
                headerName: 'Délivré par',
                field: 'organisme',
                type: 'textWithMax',
                cellEditorParams: { maxLength: 50 },
                cellRenderer: 'textCellRendererEditable',
                flex: 1,
                minWidth: 200
              }
            ]"
            v-bind:initial_data="cv_form.formations"
            v-on:cell-value-changed="set_attribute($event, 'formations')" />
        </div>
      </div>
    </section>

    <section class="section__primary">
      <h1 class="section__title">Thématiques privilégiées</h1>

      <div class="section__body">
        <div class="description">
          Cochez vos thèmes d'expertise (plusieurs choix possible)
        </div>
        <div class="flex flex-wrap pl-8">
          <div class="my-4 flex w-full flex-wrap">
            <template
              v-for="theme in cv_form.themes"
              v-bind:key="theme.id">
              <div
                v-if="theme.id !== 14"
                class="checkbox-container">
                <input
                  type="checkbox"
                  class="checkbox-input"
                  v-bind:id="theme.id"
                  v-bind:checked="theme.checked"
                  v-on:change="updateTheme(theme.id, $event)" />
                <label
                  v-bind:for="theme.id"
                  v-bind:key="theme.id"
                  v-bind:class="`checkbox-label ${theme.checked && 'active'}`"
                  v-bind:title="theme.label">
                  {{ theme.label }}
                </label>
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
  import agGridWrapper from '@/components/utils/ag-grid/ag-grid-wrapper';

  export default {
    components: { agGridWrapper },
    props: ['set_attribute', 'cv_form'],
    methods: {
      updateTheme(id, event) {
        const themeIndex = this.cv_form.themes.findIndex(
          (theme) => theme.id === id
        );

        if (themeIndex !== -1) {
          const newThemes = this.cv_form.themes.slice(0);

          newThemes[themeIndex] = {
            ...newThemes[themeIndex],
            checked: event.target.checked
          };
          this.set_attribute(newThemes, 'themes');
          let newThemesIds = this.cv_form.themesIds
            ? this.cv_form.themesIds.slice(0)
            : [];

          if (event.target.checked && !newThemesIds.includes(id)) {
            newThemesIds = [...newThemesIds, id];
          } else if (!event.target.checked) {
            newThemesIds = newThemesIds.filter((item) => item !== id);
          }
          this.set_attribute(newThemesIds, 'themesIds');
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  .themes-box {
    border-width: 1px;
    border-radius: 2%;
    @apply border-solid border-core-300 bg-core-200 p-4 shadow-lg;
  }
  .checkbox-container {
    @apply mb-3 flex w-1/2 items-start lg:w-1/3 xl:w-1/4;

    .checkbox-input {
      @apply flex cursor-pointer;
    }
    .checkbox-label {
      @apply flex w-full flex-grow cursor-pointer self-stretch px-3 leading-none hover:text-core-900;
      word-break: break-word;
      &.active {
        @apply font-bold text-core-900;
      }
    }
  }
</style>
