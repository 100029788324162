<template>
  <nav class="sidebar">
    <transition name="slide">
      <div
        v-if="show"
        class="sidebar__list">
        <div class="list__item list__item--head">
          <header-logo />
        </div>
        <template
          v-for="espace in espaces_list"
          v-bind:key="espace.id">
          <div class="list__item list-item--level-0">
            <template v-if="!espace.restricted && !espace.menuHidden">
              <div
                v-on:click.prevent="toggleEspace(espace.id)"
                class="sidebar__link"
                v-bind:class="`bg-${espace.color} ${hasAnecsCjecEndDateToValidate ? 'pointer-events-none bg-core-400' : ''}`">
                <text-button
                  v-bind:label="espace.label"
                  height="100%"
                  label_margin="1" />
                <font-awesome-icon
                  v-bind:icon="
                    collapse_list[espace.id] ? 'chevron-down' : 'chevron-right'
                  "
                  size="lg"
                  class="mx-2 text-white" />
              </div>
              <transition name="slide-up">
                <div
                  v-if="espace.rubriques && collapse_list[espace.id]"
                  class="list__item list-item--level-1">
                  <template v-for="rubrique in espace.rubriques">
                    <div
                      v-if="!rubrique.menuHidden"
                      v-bind:key="`rubrique-${rubrique.id}`"
                      class="sidebar__link sidebar__link--sublink">
                      <text-button
                        v-bind:href="`/${espace.id}/${rubrique.id}`"
                        v-bind:label="rubrique.label"
                        height="100%"
                        label_margin="1" />
                    </div>
                    <div
                      v-if="rubrique.sous_rubriques"
                      v-bind:key="`sous-rubrique-${rubrique.id}`"
                      class="list__item list-item--level-2">
                      <template
                        v-for="sous_rubrique in rubrique.sous_rubriques"
                        v-bind:key="sous_rubrique.id">
                        <div class="sidebar__link sidebar__link--sublink">
                          <text-button
                            v-bind:href="`/${espace.id}/${rubrique.id}/${sous_rubrique.id}`"
                            v-bind:label="sous_rubrique.label"
                            height="100%"
                            label_margin="1" />
                          <font-awesome-icon
                            v-if="
                              isCurrentRoute(
                                espace.id,
                                rubrique.id,
                                sous_rubrique.id
                              )
                            "
                            icon="square"
                            v-bind:class="`text-${espace.color}`"
                            class="mx-2" />
                        </div>
                      </template>
                    </div>
                  </template>
                </div>
              </transition>
            </template>
          </div>
        </template>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="show"
        v-on:click="close"
        class="sidebar__overlay"></div>
    </transition>
  </nav>
</template>

<script>
  import headerLogo from '@/components/header/header-logo.vue';
  import textButton from '@/components/utils/text-button.vue';
  import { createNamespacedHelpers } from 'vuex';
  const { mapState: mapStateEspace } = createNamespacedHelpers('espace');
  const { mapState: mapStateAccount } = createNamespacedHelpers('account');

  export default {
    components: {
      textButton,
      headerLogo
    },
    emits: ['close'],
    data() {
      return {
        show: false,
        collapse_list: {}
      };
    },
    computed: {
      ...mapStateEspace(['espaces_list']),
      ...mapStateAccount(['hasAnecsCjecEndDateToValidate'])
    },
    created() {
      const espace = this.$route.fullPath.split('/');

      this.toggleEspace(espace[1]);

      this.$nextTick(() => {
        this.show = true;
      });
    },
    methods: {
      close() {
        this.show = false;
        this.$emit('close');
      },
      toggleEspace(value) {
        this.collapse_list[value]
          ? (this.collapse_list[value] = !this.collapse_list[value])
          : (this.collapse_list[value] = true);
      },
      isCurrentRoute(space, sub_space, sub_children) {
        const params =
          this.$router.currentRoute && this.$router.currentRoute.params;

        return (
          params &&
          params.space_id === space &&
          params.sub_space_id === sub_space &&
          params.sub_children_id === sub_children
        );
      }
    }
  };
</script>

<style lang="scss" scoped>
  @use '@/assets/css/utils/mixins' as mixin;

  .sidebar {
    @apply absolute left-0 top-0 flex h-screen w-full flex-col;

    .sidebar__overlay {
      @apply fixed inset-0 z-10 bg-black bg-opacity-50;
    }

    .sidebar__list {
      @apply z-50 m-0 h-full w-[250px] max-w-full overflow-auto bg-white p-0 shadow;

      .list__item {
        @apply flex w-full flex-wrap items-center;

        :deep(.sidebar__link) {
          @apply flex h-12 w-full cursor-pointer items-center pl-3 text-xs;

          .text-link {
            @apply justify-start text-white;
          }

          &.sidebar__link--sublink {
            @apply flex h-8;

            .text-link {
              @apply text-core-700;
            }
          }
        }

        &.list__item--head {
          @apply h-16 p-2;
        }

        &.list-item--level-1 {
          @apply py-2 pr-5;

          > .sidebar__link--sublink {
            @apply my-1 rounded-r-lg bg-core-100 shadow;
          }
        }

        &.list-item--level-2 {
          @apply pl-4;
        }
      }
    }
  }
</style>
