import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"m11.714 13.791-.32.332a2 2 0 0 0-.421 2.124l.491 1.245a1 1 0 0 0 .93.633h4.215a1 1 0 0 0 .86-.49l.745-1.259a2 2 0 0 0-.297-2.424l-1.325-1.341m.789-2.091c0 1.668-1.269 3.02-2.833 3.02s-2.834-1.352-2.834-3.02 1.269-3.02 2.834-3.02c1.564 0 2.833 1.352 2.833 3.02\"></path><path stroke=\"currentColor\" d=\"M13.222 8.207V3a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12.222a1 1 0 0 0 1 1h7\"></path><rect width=\"2.267\" height=\"2.267\" x=\"4.978\" y=\"3.977\" stroke=\"currentColor\" stroke-width=\".4\" rx=\".4\"></rect><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\".4\" d=\"M8.334 4.53h1.6m-1.6 1.196h3.2\"></path><rect width=\"2.267\" height=\"2.267\" x=\"4.978\" y=\"7.844\" stroke=\"currentColor\" stroke-width=\".4\" rx=\".4\"></rect><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\".4\" d=\"M8.334 8.396H11M8.334 9.592H10\"></path><rect width=\"2.267\" height=\"2.267\" x=\"4.978\" y=\"11.711\" stroke=\"currentColor\" stroke-width=\".4\" rx=\".4\"></rect><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\".4\" d=\"M8.334 12.263H10.5m-2.166 1.196h1.14\"></path>", 8)
  ])))
}
export default { render: render }