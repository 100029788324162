<template>
  <div
    ref="dropdown"
    class="refonte-dropdown"
    v-bind:class="`${isDropdownOpen ? 'dropdown-open' : ''} ${props.on_search ? 'dropdown-with-search' : ''} ${props.footer_button_on_click ? 'dropdown-with-footer' : ''} ${props.class}`"
    v-on:click="toggleDropdown()">
    <slot></slot>
    <div
      v-if="isDropdownOpen"
      v-on:click.stop
      class="dropdown-container"
      v-bind:class="{ 'dropdown-container-right': dropdown_align_right }">
      <div class="dropdown-content">
        <refonte-search
          v-if="props.on_search"
          v-bind:on_search="props.on_search" />
        <refonte-separator v-if="props.on_search" />
        <div
          class="dropdown-content-items"
          v-on:click="toggleDropdown()">
          <slot name="dropdownContent"></slot>
        </div>
      </div>
      <refonte-button
        v-if="props.footer_button_on_click"
        v-on:click="
          () => {
            props.footer_button_on_click();
            toggleDropdown();
          }
        "
        v-bind:disabled="props.footer_button_disabled"
        size="sm"
        class="dropdown-footer">
        {{ props.footer_button_label }}</refonte-button
      >
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onBeforeUnmount, onMounted, ref } from 'vue';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import RefonteSearch from '@/components/refonte/inputs/refonte-search.vue';

  const props = withDefaults(
    defineProps<{
      dropdown_align_right?: boolean;
      on_search?: Function;
      on_open?: Function;
      footer_button_label?: string;
      footer_button_on_click?: Function;
      footer_button_disabled?: boolean;
      class?: string;
    }>(),
    {
      dropdown_align_right: false,
      footer_button_label: 'Appliquer',
      footer_button_disabled: false,
      class: ''
    }
  );

  const isDropdownOpen = ref<boolean>(false);
  const dropdown = ref<HTMLDivElement>();

  function toggleDropdown() {
    if (props.on_open && !isDropdownOpen.value) {
      props.on_open();
    }
    isDropdownOpen.value = !isDropdownOpen.value;
  }
  function closeDropdown(e: Event) {
    if (dropdown.value && !dropdown.value.contains(e.target as HTMLElement)) {
      isDropdownOpen.value = false;
    }
  }
  onMounted(() => {
    document.addEventListener('click', closeDropdown);
  });
  onBeforeUnmount(() => {
    document.removeEventListener('click', closeDropdown);
  });
</script>

<style lang="scss" scoped>
  @use 'sass:color';
  .refonte-dropdown {
    @apply relative;

    .dropdown-container {
      @apply absolute flex flex-col items-center bg-refonte-white text-refonte-black;
      z-index: 10;
      top: calc(100% + 4px);
      gap: 12px;
      padding: 8px;
      min-width: 200px;
      width: auto;
      max-width: 272px;
      border-radius: 4px;
      box-shadow: 0 3px 9px 0 #a9b2d1;

      .dropdown-content {
        @apply flex flex-col items-center self-stretch;

        .dropdown-content-items {
          @apply flex flex-col items-start self-stretch overflow-y-auto overflow-x-hidden;
          max-height: 512px;

          &::-webkit-scrollbar {
            width: 8px;
          }
          &::-webkit-scrollbar-track {
            box-shadow: none;
            background: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background: #505d8a;
            border-radius: 8px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: color.adjust(#d9e0f9, $blackness: 10%);
            cursor: pointer;
          }
        }
      }
      .dropdown-footer {
        margin-bottom: 8px;
      }
      &.dropdown-container-right {
        right: 0;
        left: unset;
      }
    }

    &.dropdown-with-search {
      .dropdown-container {
        min-width: 272px;
        .dropdown-content-items {
          max-height: 474px;
        }
      }
    }
    &.dropdown-with-footer {
      .dropdown-container {
        .dropdown-content-items {
          max-height: 460px;
        }
      }
    }
    &.dropdown-with-search.dropdown-with-footer {
      .dropdown-container {
        .dropdown-content-items {
          max-height: 422px;
        }
      }
    }
  }
</style>
