import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      d: "M5.922 10.113 3 13m10-5.876c0 2.277-1.87 4.124-4.175 4.124-2.306 0-4.175-1.847-4.175-4.124C4.65 4.846 6.52 3 8.825 3 11.131 3 13 4.846 13 7.124Z"
    }, null, -1)
  ])))
}
export default { render: render }