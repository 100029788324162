import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M2 4v12M2 4h16M2 4l6.5 6.688M2 16h16M2 16l6.5-5.312M18 16V4m0 12-6.5-5.312M18 4l-6.5 6.688m0 0-.183.16a2 2 0 0 1-2.634 0l-.183-.16"
    }, null, -1)
  ])))
}
export default { render: render }