<template>
  <div>
    <modal-box
      v-if="evaluation_show"
      ref="modal_test_satisfaction"
      width_class="w-full max-w-6xl"
      overflow_body
      fill_height
      is_survey
      survey_label="Test de satisfaction"
      v-on:close="close"
      v-on:close-survey="openModalAlertClose"
      no_outside_click>
      <template v-slot:modal-header>
        <h1
          v-if="session"
          class="px-6 text-3xl font-bold text-core-600">
          {{ session.titre }}
          <div
            class="mt-2 w-full border-l-4 pl-3 text-lg font-normal leading-none">
            {{ displayDates(session.sessionDates).join(', ') }}
          </div>
        </h1>
        <h1
          v-if="titre"
          class="satisfaction__title">
          {{ titre }}
        </h1>
      </template>

      <template v-slot:modal-body>
        <div class="mb-8">
          <template v-if="loading">
            <spinner
              sizes="w-20 h-20"
              thickness="border-8" />
          </template>
          <template v-else>
            <div
              v-if="completed"
              class="p-4">
              <message-box
                v-bind:source="'Merci d\'avoir répondu au questionnaire, votre réponse a bien été prise en compte.'"
                border_color="w-full border-yellow-500"
                font_style="text-lg" />
            </div>
            <template v-else>
              <surveyjs-wrapper
                ref="surveyjs_wrapper"
                v-on:complete="complete"
                v-on:scroll_action="scrollModalToTop"
                v-bind:json="surveyText" />
              <div
                class="mx-10 font-bold italic text-orange-500"
                v-if="surveyCatcher && surveyCatcher.isLastPage && !completed">
                Pour votre information, en soumettant ce questionnaire, vous
                acceptez que vos réponses soient exploitées par votre employeur
                pour améliorer les pratiques internes, en conformité avec le
                RGPD.
              </div>
            </template>
          </template>
        </div>
      </template>

      <template v-slot:modal-footer>
        <div
          v-if="surveyCatcher"
          class="inline-flex w-full justify-end space-x-4 text-lg">
          <div
            v-if="!surveyCatcher.isFirstPage && !completed"
            class="w-64 cursor-pointer rounded bg-teal-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-teal-400"
            v-on:click="surveyWrapper.prevPage()">
            Précédent
          </div>

          <div
            v-if="!surveyCatcher.isLastPage"
            class="w-64 cursor-pointer rounded bg-teal-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-teal-400"
            v-on:click="surveyWrapper.nextPage()">
            Suivant
          </div>

          <div
            v-if="surveyCatcher.isLastPage && !completed"
            class="w-64 cursor-pointer rounded bg-yellow-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-yellow-400"
            v-on:click="surveyWrapper.completeLastPage()">
            Terminer
          </div>
          <div
            v-else-if="completed"
            class="w-64 cursor-pointer rounded bg-core-200 px-5 py-3 text-center font-bold text-core-600 shadow hover:bg-core-100"
            v-on:click="close()">
            Fermer
          </div>
        </div>
      </template>
    </modal-box>

    <modal-box
      v-if="modal_alert_close"
      v-bind:width_class="'w-full max-w-3xl'"
      overflow_body
      v-bind:header="true"
      no_outside_click
      no_close_btn>
      <template v-slot:modal-header>
        <h1 class="text-xl font-bold text-core-600">Fermer</h1>
      </template>
      <template v-slot:modal-body>
        <div class="p-6 text-lg">
          <p class="mb-2">Etes-vous sûr de vouloir fermer ce questionnaire ?</p>
          <p class="font-bold">Vos réponses ne seront pas enregistrées.</p>
        </div>
      </template>
      <template v-slot:modal-footer>
        <div class="flex w-full justify-between">
          <div
            class="w-64 cursor-pointer rounded bg-core-200 px-5 py-3 text-center font-bold text-core-600 shadow hover:bg-core-100"
            v-on:click="closeModalAlertClose()">
            Annuler et poursuivre
          </div>
          <div
            class="w-64 cursor-pointer rounded bg-jinius-red px-5 py-3 text-center font-bold text-white shadow hover:opacity-75"
            v-on:click="close()">
            Fermer le questionnaire
          </div>
        </div>
      </template>
    </modal-box>
  </div>
</template>
<script>
  import { convertStringToDate } from '@/assets/data/ag_grid_fields_map';
  import messageBox from '@/components/utils/message-box';
  import modalBox from '@/components/utils/modal-box.vue';
  import Spinner from '@/components/utils/spinner.vue';
  import SurveyjsWrapper from '@/components/utils/surveyjs-wrapper';
  import { API } from '@/http-common';
  import { createNamespacedHelpers } from 'vuex';
  const { mapState: mapStateClient } = createNamespacedHelpers('client');

  export default {
    components: {
      SurveyjsWrapper,
      modalBox,
      Spinner,
      messageBox
    },
    props: {
      evaluation_show: Boolean,
      onclose: Function,
      titre: String,
      session: Object,
      satisfaction: Object,
      client_satisfaction_id: Number,
      fetch_data: Function,
      formateur: {
        type: Boolean,
        default: false
      },
      entreprise: {
        type: Boolean,
        default: false
      },
      participant: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      modal_alert_close: false,
      surveyText: {},
      loading: false,
      completed: false,
      surveyCatcher: null,
      surveyWrapper: null
    }),
    computed: { ...mapStateClient(['profile']) },

    async created() {
      this.loading = true;
      await this.fetchSatisfaction();
      this.fetchSurveyWrapper();
      this.loading = false;
    },
    methods: {
      fetchSurveyWrapper() {
        this.surveyCatcher = this.$refs.surveyjs_wrapper
          ? this.$refs.surveyjs_wrapper.survey
          : null;
        this.surveyWrapper = this.$refs.surveyjs_wrapper
          ? this.$refs.surveyjs_wrapper
          : null;
      },
      async close() {
        if (this.completed) {
          await this.fetch_data();
        }
        this.completed = false;
        this.onclose();
      },
      async complete(result) {
        this.loading = true;
        const response = await API.post(
          this.getURL(),
          result.data,
          this.$header_skip_redirection
        );

        if (response && response.data) {
          this.completed = true;
        }
        this.loading = false;
      },
      getURL() {
        if (this.formateur) {
          return this.getFormateurURL(this.session);
        } else if (this.participant) {
          return this.getParticipantURL(this.session);
        } else if (this.entreprise) {
          return this.getClientURL();
        }
      },
      getFormateurURL(session) {
        const { produitId, sessionId } = session;

        return `formateur/satisfaction?parcoursProduitId=${produitId}&sessionId=${sessionId}`;
      },
      getParticipantURL(session) {
        const { produitId, participantId, type, produit } = session;
        const isElearning = type && type.id === 2 && !produit;
        const participants = isElearning
          ? 'elearningParticipantId'
          : 'sessionParticipantId';

        return `participant/satisfaction?parcoursProduitId=${produitId}&${participants}=${participantId}`;
      },
      getClientURL() {
        const clientId = this.profile.activeEntrepriseId;

        return `client/${clientId}/satisfaction/${this.client_satisfaction_id}`;
      },
      async fetchSatisfaction() {
        this.loading = true;
        if (this.satisfaction || this.client_satisfaction_id) {
          const response = await API.get(
            this.getURL(),
            this.$header_skip_redirection
          );

          if (response && response.data) {
            this.surveyText = response.data;
          }
        }
        this.loading = false;
      },
      displayDates(dates) {
        const sortedDates = dates
          .slice()
          .sort(
            (a, b) =>
              convertStringToDate(a.date).getTime() -
              convertStringToDate(b.date).getTime()
          );

        return sortedDates.map(
          (date) =>
            `${date.date}${date.matin && !date.aprem ? ' (matin)' : ''}${!date.matin && date.aprem ? ' (après-midi)' : ''}`
        );
      },
      openModalAlertClose() {
        this.modal_alert_close = true;
      },
      closeModalAlertClose() {
        this.modal_alert_close = false;
      },
      scrollModalToTop() {
        const target =
          this.$refs.modal_test_satisfaction.$el.getElementsByClassName(
            'modal_body--is-survey'
          );

        return (target[0].scrollTop = 0);
      }
    }
  };
</script>
<style lang="scss" scoped>
  .label {
    @apply cursor-pointer px-4 font-normal hover:font-bold hover:text-jinius-green;
    &.active {
      @apply font-bold text-jinius-green;
    }
  }
  :deep(.sv-title) {
    @apply m-0 py-0;
    .sv-header__text {
      @apply pl-8;
    }
  }
  .satisfaction__title {
    width: 80%;
    @apply overflow-hidden overflow-ellipsis px-6 text-3xl font-bold text-core-600;
  }
</style>
