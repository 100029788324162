import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linejoin": "round",
      d: "M15.75 7.879c0 1.56-.754 3.024-1.87 4.446-.84 1.072-1.846 2.073-2.829 3.053q-.492.489-.968.973a.117.117 0 0 1-.166 0q-.475-.484-.968-.973c-.983-.98-1.989-1.982-2.83-3.053C5.004 10.903 4.25 9.44 4.25 7.879 4.25 4.466 6.852 1.75 10 1.75s5.75 2.716 5.75 6.129Z"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "10",
      cy: "7.5",
      r: "2.341",
      stroke: "currentColor"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": ".4",
      d: "M13.125 15.411c1.143.344 1.875.872 1.875 1.464 0 1.035-2.239 1.875-5 1.875s-5-.84-5-1.875c0-.592.732-1.12 1.875-1.464"
    }, null, -1)
  ])))
}
export default { render: render }