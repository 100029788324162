import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  fill: "none",
  color: "#fff"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M10.8 9.6V6m-3 6H2.2a1 1 0 0 1-1-1V6m0 0V4.6a1 1 0 0 1 1-1h7.6a1 1 0 0 1 1 1V6M1.2 6h1.2m1.8 0h1.2m1.8 0h3.6"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M21.8 3.6h-7.6a1 1 0 0 0-1 1V6h9.6V4.6a1 1 0 0 0-1-1"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M13.2 9.6V6m2.4 6h6.2a1 1 0 0 0 1-1V6m-9.6 0V4.6a1 1 0 0 1 1-1h7.6a1 1 0 0 1 1 1V6m-9.6 0h9.6"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      "fill-rule": "evenodd",
      d: "M14 13.8a2 2 0 1 1-4 0 2 2 0 0 1 4 0m-.261 2.446a3 3 0 1 1 .707-.707l1.495 1.494a1.5 1.5 0 0 1 .139 1.961L15.1 20.3a1.5 1.5 0 0 1-1.2.6H9.018a1.5 1.5 0 0 1-1.341-.829l-.602-1.202a1.5 1.5 0 0 1 .281-1.732l.69-.69a.5.5 0 0 1 .708.707l-.69.69a.5.5 0 0 0-.094.577l.601 1.203a.5.5 0 0 0 .447.276H13.9a.5.5 0 0 0 .4-.2l.98-1.306a.5.5 0 0 0-.047-.653z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }