import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M13.111 17.96c1.333 0 1.333-1.008 1.333-1.773v-4.432m-1.333 6.205c-.904.02-1.213-.829-1.373-1.933-.075-.51-.497-.903-1.013-.903H4.007m9.104 2.836c-3.078.088-8.69 0-9.333 0-1.57 0-1.754-1.38-1.775-2.436a.395.395 0 0 1 .397-.4h1.607m0 0V4.777a1 1 0 0 1 1-1h4.549\"></path><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\".6\" d=\"M9.111 9.556H6.444m5.334 1.777h-.89m-4.444 0h1.334m4 1.778H10m-3.556 0h2.667M6.5 6.5h2\"></path><circle cx=\"12.667\" cy=\"6.444\" r=\"3.944\" stroke=\"currentColor\"></circle><circle cx=\"12.667\" cy=\"6.444\" r=\"2.467\" stroke=\"currentColor\" stroke-width=\".4\"></circle><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"m9.444 9.645-1.547 1.809a.4.4 0 0 0 .305.66h.42c.22 0 .4.179.4.4v.352a.4.4 0 0 0 .722.236l1.81-2.47M15.89 9.645l1.546 1.809a.4.4 0 0 1-.304.66h-.42a.4.4 0 0 0-.4.4v.352a.4.4 0 0 1-.723.236l-1.81-2.47\"></path>", 5)
  ])))
}
export default { render: render }