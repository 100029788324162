import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "m3.714 11.382-.82.85a2 2 0 0 0-.421 2.123l.991 2.512a1 1 0 0 0 .93.633h7.035m.285-10.737c0 2.355-1.79 4.263-4 4.263s-4-1.908-4-4.263 1.791-4.263 4-4.263 4 1.909 4 4.263"
    }, null, -1),
    _createElementVNode("circle", {
      cx: "14.4",
      cy: "13",
      r: "3.7",
      stroke: "currentColor",
      "stroke-width": ".6"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M14.21 10.918a.2.2 0 0 1 .38 0l.364 1.12a.2.2 0 0 0 .19.138h1.177a.2.2 0 0 1 .118.361l-.952.692a.2.2 0 0 0-.073.224l.364 1.119a.2.2 0 0 1-.308.223l-.952-.691a.2.2 0 0 0-.235 0l-.952.691a.2.2 0 0 1-.308-.223l.364-1.12a.2.2 0 0 0-.073-.223l-.952-.692a.2.2 0 0 1 .118-.361h1.176a.2.2 0 0 0 .19-.139z"
    }, null, -1)
  ])))
}
export default { render: render }