<template>
  <template v-if="!loading">
    <refonte-card>
      <factures-filters />
    </refonte-card>
    <refonte-card
      v-if="countLoaded"
      class="mt-[8px] !gap-0 !p-0">
      <div
        class="flex flex-wrap items-center gap-[8px] px-[12px] pb-[10px] pt-[12px]">
        <refonte-checkbox
          label="Tout sélectionner"
          v-on:input="(value) => selectAllRows(value)"
          class="!self-center" />
        <IconSeparatorVertical />
        <div class="flex flex-wrap items-start gap-[8px]">
          <refonte-button
            v-bind:disabled="!hasFacturesSelected"
            v-bind:icon_left="IconDownload"
            v-on:click="downloadFactures">
            <div class="inline-flex items-center gap-[5px]">
              Télécharger
              <IconBills />
            </div>
          </refonte-button>
        </div>
        <IconSeparatorVertical />
        <refonte-table-number
          v-if="factureCount?.nbFactures || factureCount?.nbFactures == 0"
          v-bind:number="factureCount?.nbFactures"
          v-bind:label="
            factureCount?.nbFactures > 1 ? 'Factures' : 'Facture'
          " />
        <IconSeparatorVertical />
        <refonte-table-number
          v-bind:number="factureCount?.nbFacturesARegler"
          v-if="
            factureCount?.nbFacturesARegler ||
            factureCount?.nbFacturesARegler == 0
          "
          v-bind:label="`${
            factureCount?.nbFacturesARegler > 1 ? 'Factures' : 'Facture'
          } à régler`"
          v-bind:on_click="
            () => {
              factureFilterStore.setFilter(
                FACTURES_FILTERS_CONFIGURATION.typeIds.name,
                [3]
              );
            }
          " />
      </div>
      <refonte-separator class="px-[12px]" />
      <factures-content v-if="!dataLoading" />
      <template v-else>
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </template>
    </refonte-card>
  </template>
  <template v-else>
    <spinner
      sizes="w-20 h-20"
      thickness="border-8" />
  </template>
</template>

<script setup lang="ts">
  import IconSeparatorVertical from '@/assets/img/refonte/icons/display/icon-separator-vertical.svg?component';
  import RefonteCard from '@/components/refonte/cards/refonte-card.vue';
  import RefonteCheckbox from '@/components/refonte/inputs/refonte-checkbox.vue';
  import RefonteSeparator from '@/components/refonte/separators/refonte-separator.vue';
  import { onMounted, ref, watch } from 'vue';
  import Spinner from '@/components/utils/spinner.vue';
  import FacturesContent from '@/views/_espace/_refonte/gestion/_factures/factures-content.vue';
  import {
    FACTURES_FILTERS_CONFIGURATION,
    useFactureFilterStore,
    useFactureStore
  } from '@/stores/factures';
  import { storeToRefs } from 'pinia';
  import { useEntrepriseStore } from '@/stores/entreprise';
  import RefonteTableNumber from '@/components/refonte/numbers/refonte-table-number.vue';
  import { useRoute, useRouter } from 'vue-router';
  import api_utils from '@/mixin/api_utils';
  import FacturesFilters from '@/views/_espace/_refonte/gestion/_factures/factures-filters.vue';
  import RefonteButton from '@/components/refonte/buttons/refonte-button.vue';
  import IconDownload from '@/assets/img/refonte/icons/display/icon-download.svg?component';
  import IconBills from '@/assets/img/refonte/icons/menu/icon-bills.svg?component';
  import { Filters } from '@/types/gestion/inscriptions-filters-types';
  import filters_utils from '@/mixin/utils/filters_utils';
  import http_functions from '@/mixin/http_functions';
  import { FactureCount } from '@/types/gestion/factures-filters-types';
  import { API } from '@/http-common';

  const loading = ref(false);

  const entrepriseStore = useEntrepriseStore();
  const { activeClientIds } = storeToRefs(entrepriseStore);
  const factureFilterStore = useFactureFilterStore();

  const { filters } = storeToRefs(factureFilterStore);

  const dataLoading = ref<boolean>(false);
  const factureStore = useFactureStore();
  const { hasFacturesSelected, getFacturesSelected } =
    storeToRefs(factureStore);

  const selectAllRows = (value: boolean) => {
    factureStore.selectAllFactureItems(value);
  };
  const fetchData = async () => {
    dataLoading.value = true;
    await factureStore.fetchFactures(route.query);
    dataLoading.value = false;
  };

  const fetchDefaultData = async () => {
    const query = getQuery(filters.value);
    if (filters_utils.methods.isDeepEqual(query, route.query)) {
      await fetchData();
    }
  };

  const route = useRoute();
  watch(
    () => route.query,
    async () => {
      if (initFiltersCompleted.value) {
        await fetchData();
      }
    }
  );

  const initFiltersCompleted = ref<boolean>(false);
  const initFilters = async () => {
    factureFilterStore.initFiltersFromQuery(route.query);
    initFiltersCompleted.value = true;
  };

  onMounted(async () => {
    await initFilters();
    await fetchDefaultData();
  });

  const router = useRouter();

  watch(filters, async () => {
    let query = getQuery(filters.value);
    if (activeClientIds.value && activeClientIds.value != '') {
      query = {
        ...query,
        clientIds: activeClientIds.value
      };
    }
    await router.push({ query });
  });

  const getQuery = (filters: Filters) =>
    api_utils.methods.formatParams(filters, [
      FACTURES_FILTERS_CONFIGURATION.date.name,
      FACTURES_FILTERS_CONFIGURATION.datePeriodeDebut.name,
      FACTURES_FILTERS_CONFIGURATION.datePeriodeFin.name,
      FACTURES_FILTERS_CONFIGURATION.dateFormation.name,
      FACTURES_FILTERS_CONFIGURATION.dateFormationPeriodeDebut.name,
      FACTURES_FILTERS_CONFIGURATION.dateFormationPeriodeFin.name
    ]);
  const downloadFactures = () => {
    const facturesIds = getFacturesSelected.value.map((f) => f.id).join(',');
    http_functions.methods.downloadFile(
      `client/v2/factures/zip?factureIds=${facturesIds}`,
      'factures.zip'
    );
  };

  const countLoaded = ref<boolean>(false);
  const factureCount = ref<FactureCount>();
  const fetchCount = async () => {
    countLoaded.value = false;

    const query = [];
    if (activeClientIds.value.length) {
      query.push(`clientIds=${activeClientIds.value}`);
    }

    const response = await API.get(
      `client/v2/factures/count${query.length ? `?${query.join('&')}` : ''}`
    );

    if (response && response.data) {
      factureCount.value = response.data;
    }
    countLoaded.value = true;
  };

  watch(activeClientIds, async () => {
    await fetchCount();
  });

  onMounted(async () => {
    await fetchCount();
  });
</script>

<style lang="scss" scoped></style>
