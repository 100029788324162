import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M13.387 2H6.258a1 1 0 0 0-1 1v11.765M13.388 2 17 5.989M13.387 2v3.589c0 .22.18.4.4.4H17m0 0v9.973c0 .86 0 1.994-1.5 1.994m0 0c-1.048.022-1.385-.991-1.561-2.287a1.03 1.03 0 0 0-1.01-.904H5.26M15.5 17.956c-3.462.099-9.777 0-10.5 0-1.79 0-1.978-1.598-1.998-2.792a.395.395 0 0 1 .398-.4h1.858"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": ".6",
      d: "M14 8.5h-3m3 2H8m6 2H8"
    }, null, -1),
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M9.018 5.458a.218.218 0 1 1 0 .435H6.813a.218.218 0 1 1 0-.435zm-.398.833a.223.223 0 1 1 0 .445H6.817a.223.223 0 1 1 0-.445zm1.006-2.087c.153.084.195.277.12.436l-.095.204a.206.206 0 0 1-.29.085 1.2 1.2 0 0 0-.256-.114 1 1 0 0 0-.318-.047.84.84 0 0 0-.481.139.9.9 0 0 0-.318.433q-.112.295-.112.756 0 .465.111.758a.9.9 0 0 0 .319.427q.205.135.48.135.177 0 .32-.045.142-.045.25-.11c.097-.056.227-.022.276.08l.103.217c.074.156.034.348-.119.43q-.117.063-.246.107-.275.09-.583.09-.52 0-.923-.248a1.7 1.7 0 0 1-.626-.713q-.227-.468-.227-1.128t.227-1.128a1.7 1.7 0 0 1 .626-.717Q8.266 4 8.787 4q.319 0 .593.095.128.045.246.11"
    }, null, -1)
  ])))
}
export default { render: render }