<template>
  <modal-box
    v-if="modal_show"
    v-bind:modal_mask_class_name="`modal-mask-partial ${show_side_bar ? 'lg:w-[calc(83.33%-2rem)]' : 'w-full'}`"
    v-bind:width_class="'w-full max-w-screen-md'"
    v-bind:no_outside_click="true"
    v-bind:header="false"
    v-bind:no_close_btn="true"
    v-on:close="onclose">
    <template v-slot:modal-body>
      <div class="my-3 text-justify">
        Vous acceptez que vos données à caractère personnel soient transmises au
        GIE SAFIR pour les besoins liés à l’utilisation de la plateforme de
        formation, notamment l’organisation et le suivi de la gestion des
        formations. Ces données sont strictement gérées dans le cadre du
        Règlement européen de protection des données (« RGPD » 2016/679 du 27
        avril 2016) et la loi « Informatique et Libertés » du 6 janvier 1978
        modifiée. Les mesures de sécurité appliquées par SAFIR sont consultables
        dans la politique des données disponible sur la plateforme.
      </div>
      <div class="my-3 text-justify">
        Au sein de votre espace formateur, vous avez accès aux données
        personnelles des participants des sessions que vous avez animées. Ces
        données sont consultables et ne peuvent être modifiées sous peine de
        violation des mesures de protection des données de la plateforme SAFIR.
      </div>
    </template>

    <template v-slot:modal-footer>
      <div class="inline-flex w-full justify-center space-x-6">
        <button
          class="primary__button"
          v-on:click="accept">
          J'accepte les exigences RGPD
        </button>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  export default {
    components: { modalBox },
    props: ['modal_show', 'onclose', 'accept', 'show_side_bar']
  };
</script>
<style lang="scss" scoped>
  .primary__button {
    @apply w-64 cursor-pointer rounded bg-jinius-dark-green px-5 py-3 text-center font-bold text-white shadow hover:bg-jinius-green;
  }

  .secondary__button {
    @apply w-64 cursor-pointer rounded bg-jinius-red px-5 py-3 text-center font-bold text-white shadow hover:opacity-75;
  }
</style>
