import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M10 6.167v6.38m0-6.38c1.429-1.8 5-.982 5-.982v9.406s-3.571-1.227-5 .409c-1.429-1.636-5-.409-5-.409V5.185s3.571-.817 5 .982"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M16.563 7H17v10s-4.5-1-7 0c-2.5-1-7 0-7 0V7h.438"
    }, null, -1)
  ])))
}
export default { render: render }