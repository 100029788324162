import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "16",
  height: "16",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "8",
      cy: "8",
      r: "8",
      fill: "#869DF0"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "#fff",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "m4.667 8 2.072 2.763a.4.4 0 0 0 .645-.008L11.066 5.6"
    }, null, -1)
  ])))
}
export default { render: render }