import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      width: "14",
      height: "14",
      x: "3",
      y: "3",
      fill: "#EA444C",
      rx: "7"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "#fff",
      "stroke-linecap": "round",
      d: "m8 8 2 2m2 2-2-2m0 0 2-2m-2 2-2 2"
    }, null, -1)
  ])))
}
export default { render: render }