import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      d: "M8.5 3.725C5.741 4.633 5 8.196 5 12.5c0 1.118-1 1.3-1 2.5 0 1.182 1.674 1.432 4 1.486.626.014 1.3.014 2 .014s1.374 0 2-.015c2.326-.053 4-.303 4-1.485 0-1.2-1-1.382-1-2.5 0-3.907-.754-7.815-3.57-8.776m-2.93 0A4.8 4.8 0 0 1 10 3.5c.527 0 1.002.078 1.43.224m-2.93 0c0-2.224 2.93-2.375 2.93 0"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-width": ".4",
      d: "M7.5 16c.625 2.667 4.375 2.667 5 0"
    }, null, -1)
  ])))
}
export default { render: render }