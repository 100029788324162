import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#869DF0",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": ".4",
      d: "M12 13.8H4a.8.8 0 0 1-.8-.8V3a.8.8 0 0 1 .8-.8h8a.8.8 0 0 1 .8.8v10a.8.8 0 0 1-.8.8"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-width": ".4",
      d: "M6 4.938h4m-4 1.5h4m-4 1.5h4M6 7.938h4m-4 1.5h4m-4 1.5h4"
    }, null, -1)
  ])))
}
export default { render: render }