import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  color: "#161616",
  viewBox: "0 0 20 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M7.941 3.618c0-2.157 2.47-2.157 2.47 0v3.595c0 1.798-2.47 2.301-2.47 3.596v6.112q0 0 0 0c0 1.439-2.47 1.439-2.47 0V10.81C5.47 9.61 3 9.371 3 7.213V3.618c0-2.157 2.47-2.157 2.47 0m2.471 0v3.236m0-3.236c0-2.157-2.47-2.157-2.47 0m0 0v3.236M17 16.452V2.855c0-.34-.391-.519-.633-.28-1.774 1.751-3.388 4.6-3.48 9.003-.005.22.174.4.395.4h.916a.4.4 0 0 1 .4.4v4.074c0 2.064 2.402 2.064 2.402 0"
    }, null, -1)
  ])))
}
export default { render: render }