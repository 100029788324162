<template>
  <modal-box
    v-if="show_modal"
    width_class="w-full max-w-6xl"
    overflow_body
    v-bind:header="true"
    v-bind:footer="surveyCatcher ? true : false"
    v-on:close="onclose">
    <template v-slot:modal-header>
      <div class="flex flex-grow flex-wrap items-center justify-end gap-2">
        <h1
          class="flex-grow text-2xl font-bold leading-none text-core-900 lg:text-3xl">
          {{ `Appréciation de ${participant.nom} ${participant.prenom}` }}
        </h1>
        <button
          class="primary__button"
          type="button"
          v-on:click="savePdf">
          <font-awesome-icon icon="file-pdf" />
          Exporter
        </button>
      </div>
    </template>

    <template v-slot:modal-body>
      <div
        v-if="loading"
        class="h-full w-full">
        <spinner
          sizes="w-20 h-20"
          thickness="border-8" />
      </div>

      <div
        v-else
        class="flex w-full flex-wrap space-x-4 px-8 py-4 md:space-x-0 md:space-y-4">
        <div
          class="flex w-full flex-wrap"
          v-if="detail.surveyText && detail.reponses">
          <div class="flex w-full text-lg leading-none text-core-900">
            Les réponses du participant au questionnaire sont les suivantes :
          </div>
          <div class="block w-full">
            <surveyjs-wrapper
              v-bind:hide_correction="true"
              v-bind:json="detail.surveyText"
              v-bind:data="detail.reponses"
              ref="surveyjs_wrapper" />
          </div>
        </div>
        <div
          class="flex w-full text-lg text-core-900"
          v-else>
          Le participant n'a pas saisi son appréciation.
        </div>
      </div>
    </template>

    <template v-slot:modal-footer>
      <div
        v-if="surveyCatcher"
        class="inline-flex w-full justify-end space-x-4 text-lg">
        <div
          v-if="!surveyCatcher.isFirstPage"
          class="w-64 cursor-pointer rounded bg-teal-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-teal-400"
          v-on:click="surveyWrapper.prevPage()">
          Précédent
        </div>

        <div
          v-if="!surveyCatcher.isLastPage"
          class="w-64 cursor-pointer rounded bg-teal-500 px-5 py-3 text-center font-bold text-white shadow hover:bg-teal-400"
          v-on:click="surveyWrapper.nextPage()">
          Suivant
        </div>
      </div>
    </template>
  </modal-box>
</template>
<script>
  import modalBox from '@/components/utils/modal-box.vue';
  import spinner from '@/components/utils/spinner';
  import SurveyjsWrapper from '@/components/utils/surveyjs-wrapper';
  import { API } from '@/http-common';
  import { SurveyPDF } from 'survey-pdf';
  export default {
    components: {
      modalBox,
      spinner,
      SurveyjsWrapper
    },
    props: {
      show_modal: Boolean,
      session: Object,
      participant: Object,
      onclose: Function,
      client_id: Number
    },
    data: () => ({
      loading: false,
      detail: null,
      surveyCatcher: null,
      surveyWrapper: null
    }),
    async created() {
      await this.fetchDetail();
      this.fetchSurveyWrapper();
    },
    methods: {
      fetchSurveyWrapper() {
        this.surveyCatcher = this.$refs.surveyjs_wrapper
          ? this.$refs.surveyjs_wrapper.survey
          : null;
        this.surveyWrapper = this.$refs.surveyjs_wrapper
          ? this.$refs.surveyjs_wrapper
          : null;
      },
      async fetchDetail() {
        this.loading = true;
        const clientId = this.client_id;

        if (this.session && this.participant.id) {
          const response = await API.get(
            `client/satisfaction/formation/${this.$route.query.reference}/participant/${this.participant.id}?clientId=${clientId}`,
            this.$header_skip_redirection
          );

          this.detail = response.data;
        }

        this.loading = false;
      },
      savePdf() {
        const surveyPdf = new SurveyPDF(this.detail.surveyText, {
          commercial: true,
          fontSize: 12
        });

        surveyPdf.data = this.detail.reponses;
        surveyPdf.save(
          `satisfaction_${this.participant.nom}_${this.participant.prenom}_${this.$route.query.reference}`
        );
      },
      close() {
        this.detail = null;
        this.onclose();
      }
    }
  };
</script>
<style lang="scss" scoped>
  .primary__button {
    @apply mr-2 flex cursor-pointer items-center gap-1 rounded bg-jinius-dark-green px-5 py-3 text-center font-bold text-white shadow hover:bg-jinius-green;
  }
</style>
