import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "21",
  height: "20",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "10.429",
      cy: "10",
      r: "7",
      fill: "#869DF0"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "#fff",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "m7.512 10 1.772 2.363a.4.4 0 0 0 .646-.007L13.112 7.9"
    }, null, -1)
  ])))
}
export default { render: render }